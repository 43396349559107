import React, { Component } from 'react';
import ContactForm from './ContactForm';
import { actions } from '../../../../redux/modules/cuenta/register';
import { logOut, getMe } from "../../../../redux/modules/cuenta/login";
import { connect } from 'react-redux';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import NavbarContainer from '../Navbar/Navbar';

class Contact extends Component {

    componentDidMount(){
        document.body.style.background = "";
    }

    sendEmail = (data) => {
        this.props.sendEmailContact(data);
    }

    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        const { getMe, me } = this.props;
        if (!!token && !!me.username) {
            return true;
        } else if(token) {
            getMe();
            return "Verifying"
        }
        return false;
    };

    render() {

        const { loader, logOut, me } = this.props;
        const isAuthenticated = this.isAuthenticated();

        return (
            <div>

                <NavbarContainer navToggle={this.navToggle} logOut={logOut} contact
                    user={isAuthenticated && isAuthenticated === true ? me : null}
                />

                <div className="pt-5">
                    <div className="d-flex index--padding flex-column align-items-center pb-4">
                        <div className="col-lg-8 col-md-10 col-sm-11">
                            <h4 className="text-lg-center text-left">
                                At Connect, we’re here to serve you. Just tell us what you need, and we’ll handle the rest.
                            </h4>
                        </div>
                    </div>

                    <LoadMask loading={loader}>
                        <ContactForm
                            onSubmit = {this.sendEmail}
                        />
                    </LoadMask>

                    <div className="d-flex flex-column align-items-center mb-5">
                        <p className="text-center mb-2">
                            <strong>P.O. Box 16387, Washington DC 20041</strong>
                        </p>
                        <p className="text-center mb-2">
                            <strong>
                                Give us a call:{' '}
                                <a href="tel:+18662227433" className="text-primary">+1-866-222-7433</a>
                            </strong>
                        </p>
                        <p className="text-center mb-2">
                            <strong>
                                Email:{' '}
                                <a href="mailto:info@connectbbc.com" target="_blank" className="text-primary">
                                    info@connectbbc.com
                                </a>
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

const ms2p = (state) => {
    return {
        loader: state.register.loader,
        ...state.login,
    }
}

export default connect(ms2p, { ...actions, logOut, getMe })(Contact);

