import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderFieldCheck, renderField } from '../../Utils/renderField/renderField';
import autoCompleteDirections from '../map';
import renderDatePicker from '../../Utils/renderField/renderDatePicker';
import "../../Utils/TimePicker/css/time-picker.css";
import renderTimePicker from '../../Utils/TimePicker';
import moment from 'moment';


const EditTripForm = (props) => {

    const { handleSubmit, data } = props;

    return (
        <form name="editTripForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <label htmlFor="date" className="db-font-size pb-2">Date</label>
                <Field
                    name="date"
                    placeholder="Date"
                    className="flex-1 w-100 m-0 p-0"
                    component={renderDatePicker}
                    numberOfMonths={1}
                    Val={moment(data.trip_pick_up_date)}
                    />
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="time" className="db-font-size pb-2">Time</label>
                <Field name="time" id="timepicker" Value={moment(data.trip_pick_up_date)} component={renderTimePicker} />
            </div>

            <div className="form-group has-feedback">
                <label htmlFor="pickup" className="db-font-size pb-2">Pick-Up Location</label>
                <Field name="pickup" Value={ data.trip_pick_up_location } label="Pick-Up Location" component={autoCompleteDirections}
                type="text" className="form-control" placeholder="100 Aquarium Way, Long Beach, CA, USA"/>
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="dropoff" className="db-font-size pb-2">Drop-off Location</label>
                <Field name="dropoff" Value={ data.trip_drop_off_location } label="Drop-off Location" component={autoCompleteDirections}
                type="text" className="form-control" placeholder="1 World Way, Los Angeles, CA, USA" />
            </div>
            <div className="form-group has-feedback">
                <Field
                    name="wheelchair"
                    defaultChecked = { data.wheelchair }
                    component={renderFieldCheck}
                    label={<label>&nbsp;Wheel chair accessible</label>}
                />
            </div>
            <br/>
            <div className="buttons-box">
                <button type="submit" className="btn btn-danger align-self-center">
                    BOOK RIDE
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'editTripForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            date: validators.exists()('This field is Required'),
            time: validators.exists()('This field is Required'),
            pickup: validators.exists()('This field is Required'),
            dropoff: validators.exists()('This field is Required'),
        });
    },
})(EditTripForm);
