import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NewTrip } from '../trips';

class HomePage extends Component {

    render() {
        return (
            <div id="HomePage">
                <NewTrip /> 
            </div>
        );
    }
}

export default HomePage;
