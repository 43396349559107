import React, { Component } from 'react';
import NavbarContainer from '../layout/Navbar/Navbar';
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';


class Terms extends Component{

    componentDidMount(){
        document.body.style.backgroundImage = "";
    }

    render(){
        return(
            <div>
                <NavbarContainer navToggle={this.navToggle} logOut={null} user={null} />
                <Container>
                    <Row>
                        <Col xs={12} lg={{size:10,offset:1}}>
                            <br/>
                            <br/>
                            <h5 style={{ fontFamily: "Montserrat-SemiBold", fontSize: "1.5em", color: "#D50032" }}>
                                Terms and Conditions
                            </h5>
                            <hr/>
                            <div style={{ fontFamily: "Montserrat-Regular", fontSize: "1em", color: "#0A0A0A" }}>
                                <p>
                                    Our company desires to keep all personal information of Users safe and confidential <br/>
                                    and will put forth best efforts to make this happen.
                                    <br/>
                                    However, by working with our company, User understands and agrees that we cannot guarantee <br/>
                                    absolute security of information and you will not hold Company liable for any unauthorized <br/>
                                    access of your information.
                                    <br/>
                                    We try to pursue and mantain compliance and hope this is reflected in our policies and practices.
                                    <br/>
                                    If user desires use and access of our site, user must agree to follow the Privacy Policy <br/>
                                    and revisit these documents to keep up-to-date on any or all changes to the policies.
                                    <br/>
                                    If user does not agree to abide by these policies, User is not permitted to use or access <br/>
                                    Site and must cease use of site immediately.
                                    <br/>
                                </p>
                                <h5> Collection of Data </h5>
                                <p>
                                    While using the Site, there may be points in time where you are required to <br/>
                                    shared personal information with us.
                                    <br/>
                                    For example, we may request all or some of the following: full name, telephone number, <br/>
                                    mailing address, billing information and e-mail-address.
                                    <br/>
                                    We may also collect IP address, mobile device identifer details, your location, <br/>
                                    navigation and click-stream data,
                                    <br/>
                                    the time of accesssing the Site, properties you viewed, what you searched for the duration <br/>
                                    of your visit, and other details of your activity on the Site.
                                    <br/>
                                    We may obtain data from you either by you inputting your personal details or by the <br/>
                                    automatic collection of information about you as you use the Site.
                                    <br/>
                                    <br/>
                                    <Link to="/register"> Back to register </Link>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Terms;
