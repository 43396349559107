import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import { setMe } from "./login";
import { destroy } from 'redux-form'

const SUBMIT = 'REGISTER_SUBMIT';
const LOADER = 'REGISTER_LOADER';
const ME = 'LOGIN_ME';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('wmata-user', data).then((response) => {
        localStorage.setItem('token', response.token);
        //dispatch(initializeForm('profile', response.user));
        dispatch(setMe(response.user));
        dispatch(push("/"));
    }).catch((e) => {
        NotificationManager.error('Error on register, try again', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });

};

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
};

export const sendEmailContact = (data) => (dispatch) => {
    dispatch(setLoader(true))
    api.post('user/contact', data).then( response => {
        NotificationManager.success('Your message was sent', 'SUCCESS', 5000);
        dispatch(destroy('contactForm'));
    }).catch( error => {
        console.log(error);
        NotificationManager.error('Error sending message!', 'ERROR', 0);
    }).finally( () => {
        dispatch(setLoader(false));
    })
}

export const actions = {
    onSubmit,
    logOut,
    sendEmailContact
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
};

export const initialState = {
    loader: false,
    me: {},
};

export default handleActions(reducers, initialState);
