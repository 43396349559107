import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
import trips from './modules/trips/trips';
import payment from './modules/payment/payment';
import resetPassword from './modules/cuenta/resetPassword';


export default combineReducers({
    form: formReducer,
    login,
    register,
    profile,
    resetPassword,
    usuarios,
    routing,
    notificaciones,
    trips,
    payment,
});
