/*
|----------------------------------------------------------------------------
	Time Picker - Multipurpose Responsive Time Picker main JS
	Author: MGScoder
	Author URL: https://codecanyon.net/user/mgscoder
|----------------------------------------------------------------------------
*/

import jQuery from './jquery-3.4.1';
import timepicker from './bootstrap-timepicker'

document.addEventListener("touchstart", function() {},false);
(function ($) {
	"use strict";
	
/*
|--------------------------------------------------------------------------
	STYLE-1: 12hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker1').timepicker();
	setTimeout(function() {
	  $('#timeDisplay').text($('#timepicker1').val());
	}, 100);

	$('#timepicker1').on('changeTime.timepicker', function(e) {
	  $('#timeDisplay').text(e.time.value);
	});
	
/*
|--------------------------------------------------------------------------
	STYLE-2: 12hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker2').timepicker({
		minuteStep: 5
	});
	setTimeout(function() {
	  $('#timeDisplay2').text($('#timepicker2').val());
	}, 100);

	$('#timepicker2').on('changeTime.timepicker', function(e) {
	  $('#timeDisplay2').text(e.time.value);
	});
	
/*
|--------------------------------------------------------------------------
	STYLE-3: 12hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker3').timepicker({
		minuteStep: 1
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-4: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker4').timepicker({
		minuteStep: 1,
		showMeridian: false,
		defaultTime: 'current'
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-5: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker5').timepicker({
		minuteStep: 5,
		showMeridian: false
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-6: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker6').timepicker({
		minuteStep: 1,
		showSeconds: true,
		showMeridian: false
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-7: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker7').timepicker({
		minuteStep: 5,
		showSeconds: true,
		showMeridian: false
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-8: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker8').timepicker({
		minuteStep: 5,
		secondStep: 5,
		showSeconds: true,
		showMeridian: false
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-9: 12hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker9').timepicker({
		minuteStep: 30,
		showInputs: false,
		disableFocus: true
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-10: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker10').timepicker({
		minuteStep: 1,
		showInputs: false,
		disableFocus: true,
		showMeridian: false
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-11: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker11').timepicker({
		minuteStep: 15,
		showSeconds: true,
		showInputs: false,
		disableFocus: true,
		showMeridian: false
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-12: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker12').timepicker({
		minuteStep: 1,
		secondStep: 5,
		showSeconds: true,
		showInputs: false,
		disableFocus: true,
		showMeridian: false
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-13: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker13').timepicker({
		minuteStep: 1,
		maxHours: 18,
		showInputs: false,
		disableFocus: true,
		showMeridian: false
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-14: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker14').timepicker({
		minuteStep: 1,
		maxHours: 20,
		secondStep: 5,
		showSeconds: true,
		showInputs: false,
		disableFocus: true,
		showMeridian: false
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-15: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker15').timepicker({
		minuteStep: 1,
		minHours: 8,
		maxHours: 20,
		showInputs: false,
		disableFocus: true,
		showMeridian: false
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-16: 24hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker16').timepicker({
		minuteStep: 1,
		minHours: 10,
		maxHours: 18,
		secondStep: 5,
		showSeconds: true,
		showInputs: false,
		disableFocus: true,
		showMeridian: false
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-17: 12hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker17').timepicker({
		minuteStep: 1,
		secondStep: 5,
		showSeconds: true
	});
		
/*
|--------------------------------------------------------------------------
	STYLE-18: 12hr mode
|--------------------------------------------------------------------------
*/
	$('#timepicker18').timepicker({
		minuteStep: 1,
		secondStep: 5,
		showSeconds: true,
		showInputs: false,
		disableFocus: true
	});
	
	
	
})(jQuery);

/*
|--------------------------------------------------------------------------
	End
|--------------------------------------------------------------------------
*/