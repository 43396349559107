import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction } from 'validate-redux-form';
import { renderField, SelectField } from '../../Utils/renderField/renderField';
import { Tooltip } from 'reactstrap';

const status = {
    pending: 0,
    approved: 1,
    denied: 2
}

const states = [
    { "label": "Alabama", "value": 1 },
    { "label": "Alaska", "value": 2 },
    { "label": "Arizona", "value": 3 },
    { "label": "Arkansas", "value": 4 },
    { "label": "California", "value": 5 },
    { "label": "Colorado", "value": 6 },
    { "label": "Connecticut", "value": 7 },
    { "label": "Delaware", "value": 8 },
    { "label": "Florida", "value": 9 },
    { "label": "Georgia", "value": 10 },
    { "label": "Hawaii", "value": 11 },
    { "label": "Idaho", "value": 12 },
    { "label": "Illinois", "value": 13 },
    { "label": "Indiana", "value": 14 },
    { "label": "Iowa", "value": 15 },
    { "label": "Kansas", "value": 16 },
    { "label": "Kentucky", "value": 17 },
    { "label": "Louisiana", "value": 18 },
    { "label": "Maine", "value": 19 },
    { "label": "Maryland", "value": 20 },
    { "label": "Massachusetts", "value": 21 },
    { "label": "Michigan", "value": 22 },
    { "label": "Minnesota", "value": 23 },
    { "label": "Mississippi", "value": 24 },
    { "label": "Missouri", "value": 25 },
    { "label": "Montana", "value": 26 },
    { "label": "Nebraska", "value": 27 },
    { "label": "Nevada", "value": 28 },
    { "label": "New Hampshire", "value": 29 },
    { "label": "New Jersey", "value": 30 },
    { "label": "New Mexico", "value": 31 },
    { "label": "New York", "value": 32 },
    { "label": "North Carolina", "value": 33 },
    { "label": "North Dakota", "value": 34 },
    { "label": "Ohio", "value": 35 },
    { "label": "Oklahoma", "value": 36 },
    { "label": "Oregon", "value": 37 },
    { "label": "Pennsylvania", "value": 38 },
    { "label": "Rhode Island", "value": 39 },
    { "label": "South Carolina", "value": 40 },
    { "label": "South Dakota", "value": 41 },
    { "label": "Tennessee", "value": 42 },
    { "label": "Texas", "value": 43 },
    { "label": "Utah", "value": 44 },
    { "label": "Vermont", "value": 45 },
    { "label": "Virginia", "value": 46 },
    { "label": "Washington", "value": 47 },
    { "label": "West Virginia", "value": 48 },
    { "label": "Wisconsin", "value": 49 },
    { "label": "Wyoming", "value": 50 }
];

const ProfileForm = (props) => {

    const { handleSubmit, me } = props;

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
            <form action="" onSubmit={handleSubmit} className="py-4">
                <h2 style={{ fontFamily: "Roboto-Medium",
                    fontSize: "1.4em",
                    color: "#D50032" }}>
                    &nbsp;&nbsp; ACCOUNT INFO &nbsp;
                    {me.profile.account_status == status.denied &&
                        <span>
                        <span href="#" id="TooltipExample">
                            <i class="fa fa-info-circle" style={{ color: "#D50032" }} aria-hidden="true"></i>
                        </span>
                        <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}
                        style={{ backgroundColor: "#353D4A", color: "#fff" }}>
                            {`Message: ${me.profile.account_status_message}`}
                        </Tooltip>
                        </span>
                    }
                </h2>
                <div className="mb-4 ">
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row mb-0">
                        <div className="form-group has-feedback flex-1 mx-3 mb-0">
                            <small> WMata ID </small>
                            <h6 className="mb-0"> { me.username } </h6>
                        </div>
                    </div>
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                        <div className="form-group has-feedback flex-1 mx-3">
                            <div className="form-group has-feedback">
                                <small htmlFor="username">Fist Name</small>
                                <Field name="first_name" placeholder="First Name" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="form-group has-feedback">
                                <small htmlFor="username">Last Name</small>
                                <Field name="last_name" placeholder="Last Name" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="form-group has-feedback">
                                <small htmlFor="username">Email</small>
                                <Field name="email" placeholder="Email" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="form-group has-feedback">
                                <small htmlFor="first_name">Phone Number</small>
                                <Field name="profile.cell_phone" placeholder="Phone Number" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="form-group has-feedback flex-1 mx-3">
                             <div className="form-group has-feedback">
                                <small htmlFor="address">Address</small>
                                <Field
                                    name="profile.address"
                                    numberFormat={"+(502) ####-####"}
                                    placeholder="Address"
                                    component={renderField}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback">
                                    <small htmlFor="city">City</small>
                                    <Field name="profile.city" placeholder="City" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="form-group has-feedback">
                                <small htmlFor="state">State</small>
                                <Field name="profile.state" placeholder="State" component={SelectField} options={states} className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="p-0 pt-1 d-flex flex-column flex-md-row mb-0">
                        <p className="px-2 mb-0 pb-0 font-weight-bold">Change password</p>
                    </div>
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row mb-0">
                        <div className="form-group has-feedback flex-1 mx-3 mb-0">
                            <div className="form-group has-feedback">
                                <small htmlFor="newPassword"> New Password</small>
                                <Field
                                    name="password"
                                    type="password"
                                    placeholder="New Password"
                                    className="w-100 m-2"
                                    component={renderField}
                                />
                            </div>
                        </div>
                        <div className="form-group has-feedback flex-1 mx-3 mb-0">
                            <div className="form-group has-feedback">
                                <small htmlFor="confirmNewPassword"> Confirm New Password </small>
                                <Field
                                    name="confirm_password"
                                    type="password"
                                    placeholder="Confirm Password"
                                    className="w-100 m-2"
                                    component={renderField}
                                />
                            </div>
                        </div>
                    </div>
                    <div align="right">
                        <button className="btn btn-danger mx-auto mb-3"> Save </button>
                        &nbsp;&nbsp;
                    </div>
                </div>
            </form>
        );
};

export const matchPassword = (pass, confirm) => validatorFromFunction(value => {
    return pass === confirm;
});

export default reduxForm({
    form: 'profile', // a unique identifier for this form
    validate: data => validate(data, {
        confirm_password: matchPassword(data.password, data.confirm_password)()('The passwords doesn\'t match')
    })
})(ProfileForm);
