import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import { NavLink } from "react-router-dom";

class SideBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dropdownOpen: false
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    render() {

        const { logOut } = this.props;
        
        return ( 
            <Navbar light style={{ backgroundColor: "#fff" }} className="SideBar col-12 col-md-12 py-3" expand="md">
                <NavbarToggler onClick={this.toggle.bind(this)} />
                <Collapse className="mr-0"  isOpen={this.state.isOpen} navbar>
                    <div className='w-100 align-items-end align-items-lg-center'>
                        <NavLink to="/my-trips" className="nav-item" activeClassName="active">
                            My trips
                        </NavLink>
                        <NavLink to="/account-info" className="nav-item" activeClassName="active">
                            Account info
                        </NavLink>
                        <NavLink to="/payment-options" className="nav-item" activeClassName="active">
                            Payment Options
                        </NavLink>
                        <NavLink  onClick={logOut} to="/login" className="nav-item" activeClassName="active">
                            Log out
                        </NavLink>
                    </div>
                </Collapse>
            </Navbar>
        );
    }
}

export default SideBar;