import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField } from '../Utils/renderField';


const NewPasswordForm = (props) => {

    const { handleSubmit } = props;

    return (
        <form name="newForm" className="form-validate mb-lg" onSubmit={handleSubmit}>

            <div className="form-group has-feedback">
                <label htmlFor="password">New password</label>
                <Field
                    name="password"
                    label="New password"
                    component={renderField}
                    type="password"
                    className="form-control"
                />
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="confirmPassword">Confirm New Password</label>
                <Field
                    name="confirmPassword"
                    label="Confirm password"
                    component={renderField}
                    type="password"
                    className="form-control"
                />
            </div>
            <br/>
            <div className="buttons-box">
                <button type="submit" className="btn btn-danger align-self-center" value = {props.token}>CONFIRM</button>
            </div>
        </form>
    );
};

export const matchPassword = (pass, confirm) => validatorFromFunction(value => {
    return pass === confirm;
});

export default reduxForm({
    form: 'newForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            password: validators.exists()('This field is required'),
            confirmPassword: combine(
                validators.exists()('This field is required'),
                matchPassword(data.password, data.confirmPassword)()('The passwords doesn\'t match')
             ),
        });
    },
})(NewPasswordForm);
