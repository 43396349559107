import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import LoginForm from './LoginForm';
import LoadMask from "Utils/LoadMask/LoadMask";
import NavbarContainer from '../../layout/Navbar/Navbar';

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        document.body.style.background = `url('${require("../../../../../assets/img/background.png")}')`;
    }

    render() {

        const { onSubmit, loader } = this.props;

        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }

        return (
            <div>
                <NavbarContainer navToggle={this.navToggle} logOut={null} user={null} />
                <div className="wrapper-login" style = {{ marginTop: "-97px" }}>
                    <div className="card card-login">
                        <h5 className="card-title"> Welcome Back </h5>
                        <div className="form">
                            <LoadMask loading={ loader } dark blur >
                                <LoginForm onSubmit={ onSubmit }  />
                                <br/>
                                <p className="link"><Link to="/reset-password"> Forgot password? </Link></p>
                                <p className="link"><Link to="/register"> Register </Link></p>
                                <br/>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
