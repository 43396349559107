import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import RegisterForm from './RegisterForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import NavbarContainer from "../../layout/Navbar/Navbar";


class Registro extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount() {
        document.body.style.background = `url('${require("../../../../../assets/img/background.png")}')`;
    }

    render() {

        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }

        return (
            <div>
                <NavbarContainer navToggle={this.navToggle} logOut={null} user={null} />
                <div id="register">
                    <div className="wrapper wrapper-register">
                        <div className="card card-register">
                            <h5>WELCOME</h5>
                            <p className="description">
                            Complete the short form below and our team of experts will be in touch soon
                            </p>
                            <LoadMask loading={loader} light>
                                <RegisterForm onSubmit={onSubmit} />
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Registro;
