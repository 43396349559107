import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';


const LoginForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <label htmlFor="username" className="db-font-size pb-2">WMATA ID Number</label>
                <Field
                    name="username"
                    label="WMATA ID Number"
                    placeholder="WMATA ID Number"
                    component={renderField}
                    type="text"
                    className="form-control" />
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="password" className="db-font-size pb-2">Password</label>
                <Field
                    name="password"
                    label="Password"
                    placeholder="Password"
                    component={renderField}
                    type="password"
                    className="form-control"
                />
            </div>
            <div className="buttons-box">
                <button type="submit" className="btn btn-danger align-self-center">LOGIN</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('This field is required'),
            password: validators.exists()('This field is required'),
        });
    },
})(LoginForm);
