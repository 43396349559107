import React, { Component } from "react";
import { SingleDatePicker } from "react-dates";
import classNames from 'classnames';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import "./DatePicker.css";

moment.locale('en');

class renderDatePicker extends Component {

    state = {
        dpFocused: false
    };

    componentDidMount(){
        if(this.props.Val){
            this.props.input.onChange(this.props.Val);
        }
    }

    onFocusChange = ({ focused }) => {
        this.setState({ dpFocused: focused })
    }

    render() {

        const { input, className, numberOfMonths, placeholder, id, meta: { touched, error } } = this.props;
        const invalid = touched && error;

        return (
            <div className={classNames(`form-control w-100 ${className}`, { 'is-invalid': invalid })}>
                <SingleDatePicker
                    placeholder={placeholder}
                    date={input.value ? moment(input.value) : null}
                    focused={this.state.dpFocused}
                    onDateChange={(value) => {
                        input.onChange( value );
                    }}
                    isDayBlocked={(day) => {
                        let today = new Date();
                        today = new Date(today.getFullYear(), today.getMonth(), today.getDay());
                        if (new Date(day) < today)
                            return true;
                    }}
                    onFocusChange={this.onFocusChange}
                    numberOfMonths={numberOfMonths}
                    id={id ? id : 'unique'}
                />
                {invalid && <div className="invalid-feedback">
                    {error}
                </div>}
            </div>
        )
    }
}


export default renderDatePicker;
