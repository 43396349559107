import React, {Component} from 'react';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';
import classNames from 'classnames';


class autoCompleteDirections extends Component {

	constructor(props) {
		super(props);
		this.state = {
				origin:{
					lat: null,
					lng: null,
					title: null
				},
				destination:{
					lat: null,
					lng: null,
					title: null
				},
		};
		this.autoCompleteContainerRef = React.createRef();
	 }

	handleChange = address => {
		this.setState({ address });
	};

	handleSelect = async (address) => {
		const results = await geocodeByAddress(address);
		const latLng = await getLatLng(results[0]);
		if(this.props.input.name == "dropoff"){
			this.setState({
				destination: Object.assign({}, ...this.state.destination, {lat: latLng.lat, lng: latLng.lng, title: address})
			})
			this.props.saveDestinationDirection(this.state.destination,results[0]);
		}
		if(this.props.input.name == "pickup"){
			this.setState({
				origin: Object.assign({}, ...this.state.origin, {lat: latLng.lat, lng: latLng.lng, title: address})
			});
			this.props.saveOriginDirection(this.state.origin,results[0]);
		}
		this.props.input.onChange(address);
	};

	componentDidMount(){
		if(this.props.Value){
			this.handleSelect(this.props.Value);
		}
	}

	render() {

		const searchOptions={
			language: "en",
			//types: ['address'],
			componentRestrictions: {country: "us"},
		}

		const {name, placeholder, type, meta:{touched, error}} = this.props;
		const invalid = touched && error;
		const clases = classNames('form-control');

		return (
			<div className={classNames(`w-100 p-0 input-highlight form-control`, { 'is-invalid': invalid, } )}
				style={{ maxHeight: '3rem' }}
			>
				<PlacesAutocomplete
					value={this.state.address}
					onChange={this.handleChange}
					onSelect={this.handleSelect}
					searchOptions={searchOptions}
					{...this.props.input}
				>
					{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
						<div ref={this.autoCompleteContainerRef}>
							<input
								{...getInputProps({
									placeholder: `${placeholder}`,//'Search Places ...',
									className: `location-search-input ${clases}`,
									type:`${type}`,
									onBlur: () => {
										this.autoCompleteContainerRef.current.className = '';
									},
									onFocus: () => {
										this.autoCompleteContainerRef.current.className = 'autocomplete-container';
									},
								})}
							/>

							{loading && (
                                <div className="autocomplete-dropdown-container">
                                    <div>Loading...</div>
                                </div>
                            )}
							{suggestions.length > 0 && (
                                <div className="autocomplete-dropdown-container">
									{suggestions.map(suggestion => (
										<div
											className="p-1 select-option"
											key={suggestion.index}
											{...getSuggestionItemProps(suggestion)}
										>
											<span>{suggestion.description}</span>
										</div>
									))}
								</div>
							)}
						</div>
					)}
				</PlacesAutocomplete>
				{invalid && (
					<div className="invalid-feedback">
						{ error }
					</div>
				)}
			</div>
		);
	}
}


export default autoCompleteDirections;
