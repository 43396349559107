
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import NewTripForm from './NewTripForm';


class NewTrip extends Component{

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(){
        document.body.style.background = `url('${require(`../../../../../assets/img/background-homepage.png`)}')`;
    }

    render(){

        const { onSubmit, loader } = this.props;

        return(
            <div>
                <div className="wrapper" >
                    <div className="card card-newTrip">
                        <h5 className="card-title"> Where should we go today? </h5>
                        <div className="form" id="form-newTrip">
                            <LoadMask loading={ loader } light >
                                <NewTripForm onSubmit={ onSubmit }  />
                                <br/>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default NewTrip;
