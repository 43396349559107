import React, { Component } from 'react';
import classNames from "classnames";

import './js/jquery-3.4.1'
import './js/bootstrap-timepicker'
import './js/time-picker'

import $ from './js/jquery-3.4.1'

class renderTimePicker extends Component {

    componentDidMount() {
        const timePicker = $(`#${this.props.id}`)
        timePicker.timepicker({
            minuteStep: 15,
            snapToStep: true,
            showInputs: false,
            disableFocus: false,
            showMeridian: true,
            defaultTime: ''
        })

        timePicker.on('changeTime.timepicker', (e) => this.setTimeOnState(e.time.value))
        {this.props.Value && this.setTimeOnState(this.props.Value.format("LT A")) }
    }

    setTimeOnState = (timeString) => {
        const regexHour = /\d+:/
        const regexMinutes = /:[\d]{2}/

        let hour = timeString.match(regexHour)[0].slice(0, -1)
        const minutes = timeString.match(regexMinutes)[0].slice(1)
        const [, meridian] = timeString.split(' ')

        if (hour === '12' && meridian === 'PM') {
            //pass
        } else if (hour === '12' && meridian === 'AM') {
            hour = '0'
        } else if (meridian === 'PM') {
            hour = (+hour + 12).toString()
        }
        this.props.input.onChange({hour, minutes})
    }


    render() {
        const {
            id, placeholder, type, meta: { touched, error }
        } = this.props

        const invalid = touched && error;

        return (
            <div className="d-flex input-group bootstrap-timepicker timepicker">
                <input
                    id={id}
                    type={type}
                    style={{width: "90%"}}
                    defaultValue={this.props.Value ? this.props.Value.format("LT A") : ""}
                    placeholder={placeholder}
                    className={classNames('form-control input-small time-picker-input', {'is-invalid': invalid})}
                    autoComplete="off"
                />
                <span className="d-flex justify-content-center align-items-center input-group-addon" style={{width: "10%"}}>
                    <i className="glyphicon glyphicon-time" />
                </span>
                {invalid && (
                    <div className="invalid-feedback">
                        {invalid}
                    </div>
                )}

            </div>
        )
    }
}

export default renderTimePicker;
