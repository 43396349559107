import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SUBMIT = 'NEW_PAYMENT_SUBMIT';
const LOADER = 'NEW_PAYMENT_LOADER';
const SHOW_MY_CARDS = 'SHOW_MY_CARDS';
const SET_ID_CARD = 'SET_ID_CARD';
const LOADER_DEFAULT = 'LOADER_DEFAULT';
const SHOW_MY_DELETED_CARDS = 'SHOW_MY_DELETED_CARDS';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setLoaderDefault = loaderDefault => ({
    type: LOADER_DEFAULT,
    loaderDefault
})

export const showMyCards = data => ({
    type: SHOW_MY_CARDS,
    data
});

export const showMyDeletedCards = deleteCard => ({
    type: SHOW_MY_DELETED_CARDS,
    deleteCard
});

export const setIdCard = id_card => ({
    type: SET_ID_CARD,
    id_card
})

// ------------------------------------
// Actions
// ------------------------------------

export const getMyCards = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('wmata-payment/my_cards').then((response)=>{
        dispatch(showMyCards(response));
    }).catch(()=>{

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getMyDeleteCards = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('wmata-payment/my_deleted_cards').then((response)=>{
        dispatch(showMyDeletedCards(response));
    }).catch(()=>{
        console.log("You don't have Delete cards");
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const setDefaultCard = (id) => (dispatch, getStore) => {
    dispatch(setLoaderDefault(true));
    api.post(`wmata-payment/${id}/set_default_payment`).then(()=>{
        dispatch(getMyCards());
    }).catch(()=>{
        NotificationManager.error('Error!!! can\'t set default your card, Sorry!!!', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoaderDefault(false));
    });
}

export const removeCard = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post(`wmata-payment/${id}/remove_payment`).then((response)=>{
        console.log(response);
        NotificationManager.success(response.detail,'SUCCESS',3000);
        dispatch(getMyCards());
    }).catch((e)=>{
        NotificationManager.error(`Error!!! ${e.detail} `, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const setPaymentCard = (id) => (dispatch) => {
    dispatch(setIdCard(id));
}

export const onSubmit = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    data.mm_yy = data.mm_yy.slice(0,2) + "/" + data.mm_yy.slice(2,4);
    api.post('wmata-payment/add_payment', data).then((response) => {
        NotificationManager.success(response.detail, 'Success', 0);
        dispatch(getMyCards())
    }).catch(() => {
        NotificationManager.error('Error!!! can\'t register your card, Sorry', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    onSubmit,
    getMyCards,
    getMyDeleteCards,
    setDefaultCard,
    setPaymentCard,
    removeCard,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SHOW_MY_CARDS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SHOW_MY_DELETED_CARDS]: (state, { deleteCard }) => {
        return {
            ...state,
            deleteCard,
        };
    },
    [SET_ID_CARD]: (state, { id_card }) => {
        return{
            ...state,
            id_card,
        };
    },
    [LOADER_DEFAULT]: (state, { loaderDefault }) => {
        return{
            ...state,
            loaderDefault,
        };
    }
};

export const initialState = {
    loader: false,
    loaderDefault: false,
    data: null,
    deleteCard: null,
    id_card: undefined
};

export default handleActions(reducers, initialState);
