
import React, { Component } from 'react';
import { Button, Row, Col, Modal, ModalBody, Container } from 'reactstrap';
import PropTypes from 'prop-types';

class CardPayment extends Component{

    state = {
        img: undefined,
        confirmDelete: false,
    }

    componentDidMount(){
        try{
            this.setState({ img: require(`../../../../../assets/img/${this.props.data.cardType}.svg`)})
        } catch (e) {
            this.setState({ img: require('../../../../../assets/img/default.png')})
        }
    }

    toggleConfirmDelete = () => {
        this.setState({ confirmDelete: !this.state.confirmDelete });
    }

    confirmDeleteCard = () =>{
        this.toggleConfirmDelete();
        this.props.deleteCard();
    }

    render(){
        const { data, setDefaultCard, deleteCard } = this.props;
        return(
            <Row style={{ border: "1px solid #E5E5E5", display: "flex", alignItems: "center", justifyContent: "center", padding: "1em 0", marginTop: "0.4em" }} >

                <Col xs={1} className="p-0" style={{ top: "-0.25em", margin:"10px"}}>
                    <img src={this.state.img} width="40"/>
                </Col>
                <Col xs={3}>
                    <span style={{ fontFamily: "Roboto-Regular" }}>
                        •••• {data.cardNumber}
                    </span>
                </Col>
                <Col xs={3}>
                    {data.default 
                      ? <span  style={{ fontFamily: "Roboto-Regular", fontSize: "0.9em", color: "#808285" }} > <i className="fa fa-check-circle" style={{ color: "#D50032"}} aria-hidden="true"> </i> Default </span>
                      : <Button value={data.id} onClick={ setDefaultCard } color="link"
                        style={{ padding: "0", color: "#D50032", fontFamily: "Roboto-Regular" }}>
                            Set Default
                        </Button>
                    }
                </Col>
                <Col xs={4} align="center">
                    <Button onClick = { this.toggleConfirmDelete } block color="link" style={{ padding: "0", color: "#D50032", fontFamily: "Roboto-Regular" }}> 
                        Delete Card
                    </Button>
                </Col>
                <br/>
                <Modal centered={true} isOpen={this.state.confirmDelete}
                toggle = { this.toggleConfirmDelete } >
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div>
                                        <h1 style={{ fontFamily: "Montserrat-SemiBold", fontSize: "1.5em", color: "#252F38" }} align="center"> 
                                           Are you sure to remove this card?
                                        </h1>
                                        <br/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ size: 4, offset: 2 }}>
                                    <Button block outline color="danger" onClick={this.toggleConfirmDelete} > NO </Button>
                                </Col>
                                <Col xs={4}>
                                    <Button value = { data.id } block color="danger" onClick={ this.confirmDeleteCard } > YES</Button>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
            </Row>
        )
    }
}

Modal.propTypes = {
    centered: PropTypes.bool,
    isOpen:  PropTypes.bool,
    toggle:  PropTypes.func,
    fade: PropTypes.bool,
}

export default (CardPayment);



