
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import MapWithADirectionsRenderer from '../map/DirectionRoute';
import NewPay from '../../payment/newPay';
import { Modal, ModalBody, Container, Row, Col } from 'reactstrap';


class ConfirmTrip extends Component{

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    state = {
        confirm: this.props.created,
        error: false,
        modal: false,
        responsiveMap: false
    }

    modalToggle = () => {
        this.setState({ modal: !this.state.modal });
    };

    confirm = () => {
        this.props.confirmTrip();
    }

    componentDidUpdate(){
        if(this.state.confirm != this.props.created){
            this.setState({ confirm: this.props.created })
            this.modalToggle();
        }
        if(this.state.error != this.props.error){
            this.setState({ error: this.props.error })
            this.modalToggle();
        }
    }

    setCreatedFalseTrip = () => {
        this.props.setCreatedFalseTrip();
    }

    setErrorFalseTrip = () => {
        this.props.setErrorFalseTrip();
    }

    handleSizeChange = () => {
        if(window.innerWidth < 992){
            this.setState({ responsiveMap: true })
        } else {
            this.setState({ responsiveMap: false })
        }
        let map1 = document.getElementById("mapResponsive1");
        if(window.innerHeight < 751){
            map1.style.minHeight = "675px";
            map1.style.height = "675px";
        } else {
            map1.style.height = `${window.innerHeight-97}px`;
        }
    }

    componentDidMount(){
        if(!this.props.origin.lat && !this.props.destination.lat)
            window.location.href = "/"
        this.handleSizeChange();
        window.addEventListener('resize', () => { this.handleSizeChange() });
    }

    render(){

        const { onSubmit, loader, price, time, edit} = this.props;

        return(
            <div id="ConfirmTripPage" >
                {!this.state.responsiveMap &&
                    <div id="mapResponsive1">
                        <MapWithADirectionsRenderer 
                            fromLat = { this.props.origin.lat }
                            fromLng = { this.props.origin.lng }
                            toLat = { this.props.destination.lat }
                            toLng = { this.props.destination.lng }
                            map = {1}
                        />
                    </div>
                }
                <div className="wrapper" style= {{ zIndex: "auto", paddingTop: "95px" }}>
                    <div className="card card-confirmTrip">
                        <br/>
                        {this.state.confirm 
                            ? <h5 className="card-title"> Trip Summary </h5>
                            : <h5 className="card-title">Confirm Trip Details</h5>
                        }
                        <br/>
                        <div id="ConfirmTrip" className="form">
                            <LoadMask loading={loader} light >
                                <div>
                                    <div className="d-flex flex-column">
                                        <div className="pl-3 d-flex flex-row">
                                            <div className="w-50 pr-1">
                                                <label> Date: </label>
                                                <span> { this.props.date ? this.props.date.format('MM/DD/YYYY') : "" } </span>
                                            </div>
                                            <div className="w-50 pl-1">
                                                <p>
                                                    <label> Time:&nbsp; </label>
                                                    <span>
                                                        {time
                                                            ? ( time.hour > 12
                                                                ? ( time.hour - 12 +':'+time.minutes + ' PM' )
                                                                : ( time.hour + ':' + time.minutes + ' AM' ) )
                                                            : " "
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-row">
                                                <div className="pt-2 pr-2">
                                                    <div className="Line-Circle"></div>
                                                    <div className="Line-Line"></div>
                                                </div>
                                                <div className="flex-1">
                                                    <p>
                                                        <label> Pick-Up: </label>
                                                        <span> {this.props.origin ? this.props.origin.title : ""} </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <div className="pr-2">
                                                    <div className="" style={{ height: "0.5rem" }}>
                                                        <div className="Line-Line"></div>
                                                    </div>
                                                    <div className="Line-Square"></div>
                                                </div>
                                                <div className="flex-1">
                                                    <p>
                                                        <label> Drop-Off: </label>
                                                        <span> {this.props.destination ? this.props.destination.title : ""} </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.responsiveMap &&
                                        <div  className="mapResponsive2">
                                            <MapWithADirectionsRenderer 
                                                fromLat = { this.props.origin.lat }
                                                fromLng = { this.props.origin.lng }
                                                toLat = { this.props.destination.lat }
                                                toLng = { this.props.destination.lng }
                                                map = {2}
                                            />
                                        </div>
                                    }
                                        <br/>
                                    <div className="Amount">
                                        <p className="Sub-Amount"> <label>  Regular Charge: ${ price ? price.regular_price : "0.00" } </label> </p>
                                        <p className="Sub-Amount"> <label>  WMATA Subsidy: -${ price ? price.wmata_subsidy : "0.00" } </label> </p>
                                        <p className="Total-Amount"> <label>  You Pay: ${ price ? price.final_payment : "0.00" } </label> </p>
                                    </div>
                                    <br/>
                                    <div>
                                        {!this.state.confirm &&
                                            <NewPay confirm = { this.confirm }/>
                                        }
                                        <Modal centered={true} isOpen={this.state.modal} >
                                            <ModalBody>
                                                <Container>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <div>
                                                                <p align="center">
                                                                    {this.state.confirm 
                                                                        ?<i className="fa fa-check-circle fa-5x" aria-hidden="true"></i>
                                                                        :<i className="fa fa-times-circle fa-5x" aria-hidden="true" style={{ color: "#D50032" }} ></i>                                                                
                                                                    }
                                                                </p>
                                                                <br/> <br/>
                                                                <h1 style={{ fontFamily: "Montserrat-SemiBold", fontSize: "3.5em", color: "#252F38" }} align="center"> 
                                                                    { this.state.confirm ? "SUCCESS" : "OOPS" }
                                                                </h1>
                                                                <br/>
                                                                <p align="center">
                                                                    {this.state.confirm 
                                                                        ? "You ride has been booked. We look forward to seeing you."
                                                                        : "Something went wrong. Please check your method of payment."
                                                                    }
                                                                </p>
                                                                <br/> <br/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={{ size: 8, offset: 2}}>
                                                            {this.state.confirm 
                                                              ? <Link to="/my-trips" style={{ textDecoration: "none" }}> <Button block onClick={this.setCreatedFalseTrip} color="danger"> CONTINUE </Button> </Link>
                                                              : <Button block color="danger" onClick={this.setErrorFalseTrip} > GO BACK </Button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                    <br/>
                                </div>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Modal.propTypes = {
    isOpen:  PropTypes.bool,
    centered: PropTypes.bool,
    toggle:  PropTypes.func,
    fade: PropTypes.bool,
}


export default ConfirmTrip;
