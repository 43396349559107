import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import {
    renderTextArea,
    renderPhoneNumber,
    renderLabeledField,
} from '../../Utils/renderField/renderField';


const ContactForm = ({ handleSubmit }) => (
    <div className="d-flex flex-column align-items-center mb-5">
        <div className="col-lg-8 col-md-10 col-sm-10">
            <form onSubmit={handleSubmit} className="row justify-content-center">
                <div className="form-contact col-11 col-sm-10 col-md-8 py-5 px-4">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-column flex-lg-row justify-content-between inputLabeled">
                            <div className="flex-1 d-flex flex-row form-group">
                                <label htmlFor="FullName">Full name</label>
                                <Field
                                    name="fullName"
                                    component={renderLabeledField}
                                    type="text"
                                    placeholder="John"
                                    className="w-100 m-2"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row justify-content-between inputLabeled">
                            <div className="flex-1 d-flex flex-row form-group">
                                <label htmlFor="to" >Email</label>
                                <Field
                                    name="email"
                                    component={renderLabeledField}
                                    type="text"
                                    placeholder="John@email.com"
                                    className="w-100 m-2"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row justify-content-between inputLabeled">
                            <div className="flex-1 d-flex flex-row form-group">
                                <label htmlFor="schedule">Phone number</label>
                                <Field
                                    type="text"
                                    name="phoneNumber"
                                    component={renderPhoneNumber}
                                    formatTemplate="### ### ####"
                                    placeholder="123 456 7890"
                                    className="w-100 m-2"
                                />
                            </div>
                        </div>
                        <div className="form-group has-feedback m-2">
                            <Field name="message" label="message" placeholder="Message"
                                component={renderTextArea}rows={5} className="form-control"
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-end w-100 mt-1">
                        <button type="submit" className="btn btn-danger btnSend py-3 m-2">
                            <b> SEND </b>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
);

ContactForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

let contactForm = reduxForm({
    form: 'contactForm',
    validate: data =>
        validate(data, {
            fullName: validators.exists()('Required Field'),
            email: validators.regex(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)('Incorrect format'),
            phoneNumber: validators.exists()('Required Field'),
            message: validators.exists()('Required Field'),
        }),
})(ContactForm);

export default contactForm;
