import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';
import { renderFieldCheck } from '../../Utils/renderField/renderField';


const RegisterForm = (props) => {

    const { handleSubmit } = props;

    return (
        <form name="loginForm" id="register-form" className="form form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <label htmlFor="first_name">First Name</label>
                <Field name="first_name" label="Nombre" component={renderField}
                type="text" className="form-control" placeholder="First Name"/>
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="last_name">Last Name</label>
                <Field name="last_name" label="Apellido" component={renderField}
                type="text" className="form-control" placeholder="Last Name"/>
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="last_name">Email</label>
                <Field name="email" label="Email" component={renderField}
                type="email" className="form-control" placeholder="Email"/>
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="phone_number">Phone Number</label>
                <Field name="cell_phone" label="Phone Number" component={renderField}
                type="Phone" className="form-control" placeholder="Phone Number"/>
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="username">WMATA ID Number</label>
                <Field name="username" label="WMATA ID Number" component={renderField}
                type="text" className="form-control" placeholder="WMATA ID Number"/>
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="password">Password</label>
                <Field
                    name="password"
                    label="Password"
                    placeholder="Password"
                    component={renderField}
                    type="password"
                    className="form-control"
                />
            </div>
            <div className="buttons-box">
                <button type="submit" className="btn btn-danger align-self-center">REGISTER</button>
            </div>
            <p className="have-account"> Have an account?&nbsp;<Link to="/login">LOGIN</Link></p>
            <div className="form-group has-feedback termsCheck">
                <Field
                    name="default_checkbox"
                    component={renderFieldCheck}
                />
                <label style = {{ fontFamily: "Montserrat-Bold", fontSize: "9px", color: "#fff",
                    position: "relative", top: "-20px", left: "23px", width: "90%" }}
                >
                    You, the user of the App, confirm your acceptance of these <Link to = "/terms" style={{ fontSize: "inherit", color: "#D50032"  }}> App terms </Link> of use 
                    (“App Terms”). If you do not agree to these App Terms, you must immediately 
                    uninstall the App and discontinue its use. These App Terms should be read 
                    alongside our Privacy Policy and Cookie Policy. 
                </label>
            </div>

        </form>
    );
};

export default reduxForm({
    form: 'register', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            email: validators.exists()('This Field is Required'),
            username: validators.exists()('This Field is Required'),
            first_name: validators.exists()('This Field is Required'),
            last_name: validators.exists()('This Field is Required'),
            cell_phone: validators.exists()('This Field is Required'),
            password: validators.exists()('This Field is Required'),
            default_checkbox: validators.exists()('You need to accept the terms'),
        });
    },
})(RegisterForm);
