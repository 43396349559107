
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { AccountAdmin } from '../../layout';
import CardTrip from './cardTrip';
import { DropdownToggle, DropdownMenu, DropdownItem,
    UncontrolledDropdown,
} from 'reactstrap';


class MyTrips extends Component{

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(){ this.props.myTrips(); }

    filterUpcomingTrips = () => { this.props.filterTrips("2"); }

    filterTodayTrips = () => { this.props.filterTrips("1"); }

    filterPastTrips = () => { this.props.filterTrips("0"); }

    filterAllTrips = () => { this.props.myTrips(); }

    editTrip = () => { this.props.editTrip(event.target.value); }

    render(){

        const { loader, data, cancelTrip } = this.props;

        return(
            <AccountAdmin loader = { loader } >
                <Row>
                    <Col style={{ paddingBottom: "0.5em" }}>
                        <UncontrolledDropdown setActiveFromChild>
                            <DropdownToggle nav className="Link ml-0 ml-lg-4" style={{
                                fontFamily: "Montserrat-Semibold",
                                fontSize: "1em",
                                padding: "0 1em ",
                                color: "#00000080",
                            }} >
                            <span>
                                <i className="fa fa-sliders" aria-hidden="true" style={{color: "red"}}></i>
                                &nbsp; Filter Trips &nbsp;
                                <i className="fa fa-chevron-down" aria-hidden="true"></i>
                            </span>
                            </DropdownToggle>
                            <DropdownMenu style={{
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                transform:"translate3d(10%, 15%, 0px)"}}
                            >
                                <DropdownItem onClick={this.filterUpcomingTrips}>
                                    Upcoming Trips
                                </DropdownItem>
                                <DropdownItem onClick={this.filterTodayTrips}>
                                    Today Trips
                                </DropdownItem>
                                <DropdownItem onClick={this.filterPastTrips}>
                                    Past Trips
                                </DropdownItem>
                                <DropdownItem onClick={this.filterAllTrips}>
                                    All Trips
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <br/>
                    {data.count > 0 ?
                        <div>
                            {data.results.map((item) => {
                                return(
                                    <CardTrip 
                                        key = { item.id }
                                        data = { item }
                                        cancelTrip = { cancelTrip }
                                        editTrip = { this.editTrip }
                                    />
                                )
                            })
                            }
                        </div>
                        : <h1 align="center">
                            No data found
                        </h1>
                    }
                    </Col>
                </Row>
            </AccountAdmin>
        )
    }
}


export default MyTrips;
