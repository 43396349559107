import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import moment from 'moment';
import tz from 'moment-timezone';

const SUBMIT = 'NEW_TRIP_SUBMIT';
const LOADER = 'NEW_TRIP_LOADER';
const NEW_TRIP = 'NEW_TRIP';
const SAVE_ORIGIN_DIRECTION = 'SAVE_ORIGIN_DIRECTION';
const SAVE_DESTINATION_DIRECTION = 'SAVE_DESTINATION_DIRECTION';
const SHOW_DATA_PRICE = 'SHOW_DATA_PRICE';
const CREATED_TRIP = 'CREATED_TRIP';
const SHOW_MY_TRIPS = 'SHOW_MY_TRIPS';
const SHOW_EDIT_TRIP = 'SHOW_EDIT_TRIP';
const ERROR_CREATED_TRIP = 'ERROR_CREATED_TRIP';
const SET_EDIT = 'SET_EDIT';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setDirections = data => ({
    type: NEW_TRIP,
    date: data.date,
    time: data.time,
    wheelchair: data.wheelchair
});

export const saveOriginDirectionData = (origin, pickup) => ({
    type: SAVE_ORIGIN_DIRECTION,
    pickup,
    origin
});

export const saveDestinationDirectionData = (destination, dropoff) => ({
    type: SAVE_DESTINATION_DIRECTION,
    dropoff,
    destination
});

export const showDataPrice = (price) => ({
    type: SHOW_DATA_PRICE,
    price
});

export const createdTrip = (created) => ({
    type: CREATED_TRIP,
    created
})

export const errorCreatedTrip = (error) => ({
    type: ERROR_CREATED_TRIP,
    error
})

export const showMyTrips = (data) => ({
    type: SHOW_MY_TRIPS,
    data
})

export const setEdit = (edit,tripIdEdit) => ({
    type: SET_EDIT,
    edit,
    tripIdEdit
})

export const showEditTrip = (data) => ({
    type: SHOW_EDIT_TRIP,
    data
})

// ------------------------------------
// Actions
// ------------------------------------

export const saveOriginDirection = (origin, results) => (dispatch, getStore) =>{
    dispatch(saveOriginDirectionData(origin, results));
}

export const saveDestinationDirection = (destination, results) => (dispatch, getStore) =>{
    dispatch(saveDestinationDirectionData(destination, results));
}

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let store = getStore();
    let validPick = false;
    try {
        let pickNewDetails = {};
        let streetNumber = store.trips.pickup.address_components.find(({types}) => types.includes("street_number")) || {};
        let route = store.trips.pickup.address_components.find(({ types }) => types.includes("route")) || {};
        let locality = store.trips.pickup.address_components.find(({ types }) => types.includes("locality")) || {};
        let administrativeAreaLevel1 = store.trips.pickup.address_components.find(({ types }) => types.includes("administrative_area_level_1")) || "";
        let zipCode = store.trips.pickup.address_components.find(({ types }) => types.includes("postal_code")) || {};
        if (streetNumber.short_name && route.short_name && locality.short_name && administrativeAreaLevel1.short_name && zipCode.short_name) {
            validPick = true;
            pickNewDetails = {
                street: streetNumber.short_name + " " + route.short_name,
                city: locality.short_name,
                zip: zipCode.short_name,
                states: administrativeAreaLevel1.short_name,
                geometry: {
                    location:{
                        lat: store.trips.origin.lat,
                        lng: store.trips.origin.lng
                    }
                }
            }
            store.trips.pickup.details = pickNewDetails;
            store.trips.pickup.location = {
                description: store.trips.pickup.formatted_address
            }
        } else {
            validPick = false;
        }
    }catch(e){
        validPick = false;
    }

    let validDrop = false;
    try{
        let dropNewDetails = {};
        let streetNumber = store.trips.dropoff.address_components.find(({ types }) => types.includes("street_number")) || {};
        let route = store.trips.dropoff.address_components.find(({ types }) => types.includes("route")) || {};
        let locality = store.trips.dropoff.address_components.find(({ types }) => types.includes("locality")) || {};
        let administrativeAreaLevel1 = store.trips.dropoff.address_components.find(({ types }) => types.includes("administrative_area_level_1")) || "";
        let zipCode = store.trips.dropoff.address_components.find(({ types }) => types.includes("postal_code")) || {};
        if (streetNumber.short_name && route.short_name && locality.short_name && administrativeAreaLevel1.short_name && zipCode.short_name) {
            validDrop = true;
            dropNewDetails = {
                street: streetNumber.short_name + " " + route.short_name,
                city: locality.short_name,
                zip: zipCode.short_name,
                states: administrativeAreaLevel1.short_name,
                geometry: {
                    location: {
                        lat: store.trips.destination.lat,
                        lng: store.trips.destination.lng
                    }
                }
            }
            store.trips.dropoff.details = dropNewDetails;
            store.trips.dropoff.location = {
                description: store.trips.dropoff.formatted_address
            }
        } else {
            validDrop = false;
        }
    } catch (e) {
        validDrop = false;
    }

    const params = {
        origin: {
            latitude: store.trips.origin.lat,
            longitude: store.trips.origin.lng
        },
        destination:{
            latitude: store.trips.destination.lat,
            longitude: store.trips.destination.lng
        },
        date_str: data.date.format('MM/DD/YYYY') + ' ' + data.time.hour+':'+data.time.minutes,
        trip_id_keep_price: null
    };
    //params.date_str=moment(params.date_str,'MM/DD/YYYY HH:mm').tz('America/Los_Angeles').format('MM/DD/YYYY HH:mm');
    if(validPick && validDrop){
        api.post('trip/get_price', params).then((response) => {
            dispatch(setDirections(data));
            dispatch(showDataPrice(response));
            dispatch(push('/ConfirmTrip'))
        }).catch(() => {
            NotificationManager.error('Error!!! can\'t get trip details', 'ERROR', 0);
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else{
        NotificationManager.error('Error!!! Some address is bad', 'ERROR', 0);
        dispatch(setLoader(false));
    }
};

export const setCreatedFalseTrip = () => (dispatch, getStore) => {
    dispatch(createdTrip(false));
}

export const setErrorFalseTrip = () => (dispatch, getStore) => {
    dispatch(errorCreatedTrip(false));
}

export const confirmTrip = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let store = getStore();
    const params = {
        date: store.trips.date,
        date_str: store.trips.date.format('MM/DD/YYYY') + ' ' + store.trips.time.hour+':'+store.trips.time.minutes,
        hour: store.trips.time,
        pick_up_location: store.trips.pickup,
        drop_off_location: store.trips.dropoff,
        wheelchair: (store.trips.wheelchair === undefined ? false : store.trips.wheelchair),
        card_to_pay_id: store.payment.id_card
    };
    api.post('trip',params).then((response) => {
        dispatch(createdTrip(true));
    }).catch((e) => {
        dispatch(errorCreatedTrip(true));
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const myTrips =  () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('trip').then((response) => {
        dispatch(showMyTrips(response));
    }). catch(() => {
        NotificationManager.error('ERROR!!! Can\'t get your trips', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    })
}

export const filterTrips = (date) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('trip', {date}).then((response) => {
        dispatch(showMyTrips(response));
    }).catch( () => {
        NotificationManager.error('ERROR!!! Can\'t get your trips', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    })
}

export const cancelTrip = (tripId) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post(`trip/${tripId}/cancellation`).then((response) => {
        dispatch(myTrips());
        NotificationManager.success('SUCCESS Your trip has been canceled', 'SUCCESS', 0);
    }).catch( () => {
        NotificationManager.error('ERROR!!! Can\'t cancel your trip', 'ERROR', 0);
    }).finally( () => {
        dispatch(setLoader(true));
    })
}

export const onSubmitEdit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let store = getStore();
    let validPick = false;
    try {
        let pickNewDetails = {};
        let streetNumber = store.trips.pickup.address_components.find(({types}) => types.includes("street_number")) || {};
        let route = store.trips.pickup.address_components.find(({ types }) => types.includes("route")) || {};
        let locality = store.trips.pickup.address_components.find(({ types }) => types.includes("locality")) || {};
        let administrativeAreaLevel1 = store.trips.pickup.address_components.find(({ types }) => types.includes("administrative_area_level_1")) || "";
        let zipCode = store.trips.pickup.address_components.find(({ types }) => types.includes("postal_code")) || {};
        if (streetNumber.short_name && route.short_name && locality.short_name && administrativeAreaLevel1.short_name && zipCode.short_name) {
            validPick = true;
            pickNewDetails = {
                street: streetNumber.short_name + " " + route.short_name,
                city: locality.short_name,
                zip: zipCode.short_name,
                states: administrativeAreaLevel1.short_name,
                geometry: {
                    location:{
                        lat: store.trips.origin.lat,
                        lng: store.trips.origin.lng
                    }
                }
            }
            store.trips.pickup.details = pickNewDetails;
            store.trips.pickup.location = {
                description: store.trips.pickup.formatted_address
            }
        } else {
            validPick = false;
        }
    }catch(e){
        validPick = false;
    }

    let validDrop = false;
    try{
        let dropNewDetails = {};
        let streetNumber = store.trips.dropoff.address_components.find(({ types }) => types.includes("street_number")) || {};
        let route = store.trips.dropoff.address_components.find(({ types }) => types.includes("route")) || {};
        let locality = store.trips.dropoff.address_components.find(({ types }) => types.includes("locality")) || {};
        let administrativeAreaLevel1 = store.trips.dropoff.address_components.find(({ types }) => types.includes("administrative_area_level_1")) || "";
        let zipCode = store.trips.dropoff.address_components.find(({ types }) => types.includes("postal_code")) || {};
        if (streetNumber.short_name && route.short_name && locality.short_name && administrativeAreaLevel1.short_name && zipCode.short_name) {
            validDrop = true;
            dropNewDetails = {
                street: streetNumber.short_name + " " + route.short_name,
                city: locality.short_name,
                zip: zipCode.short_name,
                states: administrativeAreaLevel1.short_name,
                geometry: {
                    location: {
                        lat: store.trips.destination.lat,
                        lng: store.trips.destination.lng
                    }
                }
            }
            store.trips.dropoff.details = dropNewDetails;
            store.trips.dropoff.location = {
                description: store.trips.dropoff.formatted_address
            }
        } else {
            validDrop = false;
        }
    } catch (e) {
        validDrop = false;
    }

    const params = {
        origin: {
            latitude: store.trips.origin.lat,
            longitude: store.trips.origin.lng
        },
        destination:{
            latitude: store.trips.destination.lat,
            longitude: store.trips.destination.lng
        },
        date_str: data.date.format('MM/DD/YYYY') + ' ' + data.time.hour+':'+data.time.minutes,
        trip_id_keep_price: null
    };
    if(validPick && validDrop){
        api.post('trip/get_price', params).then((response) => {
            dispatch(setDirections(data));
            dispatch(showDataPrice(response));
            dispatch(push(`/ConfirmEditTrip/${store.trips.tripIdEdit}`));
        }).catch(() => {
            NotificationManager.error('Error!!! can\'t get trip details', 'ERROR', 0);
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else{
        NotificationManager.error('Error!!! Some address is bad', 'ERROR', 0);
        dispatch(setLoader(false));
    }
};

export const editTrip = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`trip/${id}`).then((response) => {
        dispatch(setEdit(true, id));
        dispatch(showEditTrip(response));
    }).catch( () => {
        dispatch(setEdit(false, undefined));
        NotificationManager.error('ERROR!!! Can\'t get trip detail','ERROR', 2000);
    }).finally( () => {
        dispatch(setLoader(false));
    })
}

export const submitEditTrip = (pickup, dropoff) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let store = getStore();
    let new_date_str = store.trips.date.format('MM/DD/YYYY') + ' ' + store.trips.time.hour+':'+store.trips.time.minutes;
    let wheelchair_has_changed = (store.trips.wheelchair === undefined ? false : store.trips.wheelchair);
    let params = {
        new_date_str: store.trips.data.trip_pick_up_date == new_date_str ? null : new_date_str,
        new_pick_up_location: pickup,
        new_drop_off_location: dropoff,
        wheelchair_has_changed: store.trips.data.wheelchair == wheelchair_has_changed ? null : true
    };
    api.post(`trip/${store.trips.tripIdEdit}/edition`, params).then((response) => {
        dispatch(createdTrip(true));
    }).catch((e) => {
        dispatch(errorCreatedTrip(true));
    }).finally(() => {
        dispatch(setLoader(false));
    })
}

export const actions = {
    onSubmit,
    saveOriginDirection,
    saveDestinationDirection,
    confirmTrip,
    setCreatedFalseTrip,
    setErrorFalseTrip,
    myTrips,
    filterTrips,
    cancelTrip,
    editTrip,
    onSubmitEdit,
    submitEditTrip,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [NEW_TRIP]: (state,{ date, time, wheelchair}) => {
        return {
            ...state,
            date,
            time,
            wheelchair
        }
    },
    [SAVE_ORIGIN_DIRECTION]: (state,{ origin, pickup}) => {
        return {
            ...state,
            pickup,
            origin,
        }
    },
    [SAVE_DESTINATION_DIRECTION]: (state,{ destination, dropoff}) => {
        return {
            ...state,
            dropoff,
            destination,
        }
    },
    [SHOW_DATA_PRICE]: (state,{ price }) => {
        return {
            ...state,
            price
        }
    },
    [SHOW_MY_TRIPS]: (state, { data }) => {
        return {
            ...state,
            data
        }
    },
    [SHOW_EDIT_TRIP]: (state, { data }) => {
        return {
            ...state,
            data
        }
    },
    [CREATED_TRIP]: (state,{created}) => {
        return{
            ...state,
            created
        }
    },
    [ERROR_CREATED_TRIP]: (state, { error }) => {
        return {
            ...state,
            error
        }
    },
    [SET_EDIT]: (state, { edit, tripIdEdit }) => {
        return {
            ...state,
            edit,
            tripIdEdit
        }
    }
};

export const initialState = {
    loader: false,
    date: undefined,
    time: undefined,
    wheelchair: false,
    pickup: {},
    validPick: false,
    dropoff: {},
    validDrop: false,
    origin: {
        lat: undefined,
        lng: undefined,
        title: undefined
    },
    destination: {
        lat: undefined,
        lng: undefined,
        title: undefined
    },
    data: {},
    price: undefined,
    created: false,
    edit: false,
    tripIdEdit: undefined,
    error: false,
};

export default handleActions(reducers, initialState);
