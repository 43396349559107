import React from 'react';
import { compose, withProps, lifecycle, } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Polyline, Marker} from 'react-google-maps';

const MAP_KEY_URL = 'AIzaSyB_bDnueuSYXZQ0WOxySLNZct0nLwO2Y6Q'
//process.env.GOOGLE_MAPS_API_KEY;


const MapWithADirectionsRenderer = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${MAP_KEY_URL}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div  style={{ height: "100%" }} />,
    containerElement: <div style={{ height: "100%" }}/>,
    mapElement: <div style={{ height: "100%" }}/>,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({

    componentDidMount() {

      //movement of bound right to show in the available space
      let fromPosition = undefined, toPosition = undefined;
      if(this.props.map == 1){
        const lngOriginDiference = Math.abs(this.props.fromLng);
        const lngDestinationDiference = Math.abs(this.props.toLng);
        const resultLng =  Math.abs(lngOriginDiference - lngDestinationDiference) * 1.2;

        //lat and lng for map limits
        if(this.props.fromLng > this.props.toLng) {
          fromPosition = new google.maps.LatLng(this.props.fromLat, this.props.fromLng + resultLng);
          toPosition = new google.maps.LatLng(this.props.toLat, this.props.toLng);
        } else{
          fromPosition = new google.maps.LatLng(this.props.fromLat, this.props.fromLng);
          toPosition = new google.maps.LatLng(this.props.toLat, this.props.toLng + resultLng);
        }
      } else {
        fromPosition = new google.maps.LatLng(this.props.fromLat, this.props.fromLng);
        toPosition = new google.maps.LatLng(this.props.toLat, this.props.toLng);
      }
      const latlngbounds = new google.maps.LatLngBounds();
      latlngbounds.extend(fromPosition);
      latlngbounds.extend(toPosition);

      this.setState({
        onMapMounted: ref => {
          if (ref) ref.fitBounds(latlngbounds);
        },
      })

      //Render route in the map
      const DirectionsService = new google.maps.DirectionsService();

      DirectionsService.route({
        origin: new google.maps.LatLng(this.props.fromLat, this.props.fromLng),
        destination: new google.maps.LatLng(this.props.toLat, this.props.toLng),
        travelMode: google.maps.TravelMode.DRIVING,
      }, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          const coords = result.routes[0].overview_path;
          this.setState({
            directions: result,
            coords,
            toLat: this.props.toLat,
            toLng: this.props.toLng,
            fromLat: this.props.fromLat,
            fromLng: this.props.fromLng
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      });
    }
  })
)((props) => {

  const centerLat = ((+props.fromLat) + (+props.toLat)) / 2;
  const centerLng =  props.fromLng > props.toLng ? props.fromLng : props.toLng;

  return (
    <GoogleMap
      ref={props.onMapMounted}
      defaultCenter={new google.maps.LatLng(centerLat, (centerLng + centerLng * 2))}
    >
        {props.coords && (
            <Polyline
                path={props.coords}
                geodesic={true}
                options={{
                    strokeColor: "#109DFA",
                    strokeOpacity: 0.9,
                    strokeWeight: 4,
                    clickable: true
                }}
            />
        )}

        <Marker
            position={{ lat: props.fromLat, lng: props.fromLng }}
        />

        <Marker
            position={{ lat: props.toLat, lng: props.toLng }}
        />

    </GoogleMap>
  )
});


export default MapWithADirectionsRenderer
