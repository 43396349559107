import React from 'react';
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderNumber } from '../../Utils/renderField/renderField';
import moment from 'moment';
import valid  from 'card-validator'


let NewPayForm = (props) => {
    const { toggleNested, handleSubmit, card } = props;

    const submit = () => {
        event.preventDefault();
        handleSubmit();
        if(!props.admin) toggleNested();
    }
    return (
        <form name="newPayForm" className="form-validate mb-lg" onSubmit={submit}>
            <div className="container-fluid">
                <div className="row">
                    <div className="form-group has-feedback col-12" id="123">
                        <Field
                            name="card_number"
                            label="Card Number"
                            component={renderNumber}
                            decimalScale={0}
                            numberFormat={card && card.type === "american-express"
                                ? '#### ###### #####'
                                : '#### #### #### #### ####'
                            }
                            prefix=""
                            className="form-control"
                            placeholder="Your Card Number"
                        />
                    </div>
                    <div className="form-group has-feedback col-6">
                        <Field
                            name="mm_yy"
                            label="date"
                            component={renderNumber}
                            decimalScale={0}
                            numberFormat="##/##"
                            className="form-control"
                            placeholder="MM/YY"
                        />
                    </div>
                    <div className="form-group has-feedback col-6">
                        <Field
                            name="cvv"
                            label="cvv"
                            component={renderNumber}
                            decimalScale={0}
                            numberFormat={card && card.type === "american-express"
                                ? '####'
                                : '###'
                            }
                            prefix=""
                            className="form-control"
                            placeholder={`${card && card.code && card.code.name ? card.code.name : 'CVV'}`}
                        />
                    </div>
                    <div className="form-group has-feedback col-12">
                        <Field
                            name="zip_code"
                            label="zipCode"
                            component={renderNumber}
                            decimalScale={0}
                            numberFormat="#####"
                            prefix=""
                            className="form-control"
                            placeholder="Zip Code"
                        />
                    </div>
                    <br/>
                    <div className="buttons-box col-12">
                        <button type="submit" className="col-12 my-2 btn btn-danger align-self-center"> SAVE </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export const ValidatorDate = (date) => validatorFromFunction(value => {
    let month = date.slice(0,2);
    let year = date.slice(2,4);
    return month > 12 ? false : (year < moment().format('YY') ? false : true);
});

NewPayForm = reduxForm({
    form: 'newPayForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            card_number: validators.exists()('This field is Required'),
            mm_yy: combine(
                validators.exists()('This field is required'),
                ValidatorDate(data.mm_yy)()('The date is not valid')
             ),
            cvv: validators.exists()('This field is Required'),
            zip_code: validators.exists()('This field is Required'),
        });
    },
})(NewPayForm);


const selector = formValueSelector('newPayForm');
NewPayForm = connect(state => {
    const card_number = selector(state, 'card_number');
    let card = valid.number(card_number);

    return card;
})(NewPayForm);

export default connect()(NewPayForm);
