import React, { Component } from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    UncontrolledDropdown,
} from 'reactstrap';
import { Link } from "react-router-dom";

const defaultAvatar = require("assets/img/avatar-placeholder.png");


class NavbarContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dropdownOpen: false
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    render() {

        const { logOut, user } = this.props;

        return (
            <Navbar id="Navbar" light  style={{ width: "auto", backgroundColor: "#D50032" }} 
                className="col-md-12 py-3" expand="lg"
            >
                <Link className="navbar-brand" to="/" style={{textDecoration:"none"}}>
                    <div className="main-navbar__search w-100 d-md-flex d-lg-flex">
                        <div className="Navbar-Logo"> 
                            <img className="Navbar-img" src={require('assets/img/Connect_Abilities_Ride_Logo_White.png')} alt="logo" /> 
                            <span className="Navbar-line"></span>
                            <span className="Navbar-text"> Abilities Rides </span>
                        </div>
                    </div>
                </Link>

                <NavbarToggler onClick={this.toggle} style={{ display: `${!user && "none" }` }}/>
                <Collapse className="mr-0"  isOpen={this.state.isOpen} navbar>
                    <Nav navbar className='d-flex align-items-end align-items-lg-center ml-auto'>
                        {!this.props.contact && (
                            <NavItem className="my-2 my-md-0 Link">
                                <div className="Link ml-0 ml-lg-4">
                                    <span className="copyright ml-auto my-auto">
                                        <Link tabIndex="0" to="/support">
                                            <i className="material-icons"> </i>
                                            Contact Us
                                        </Link>
                                    </span>
                                </div>
                            </NavItem>
                        )}

                        <NavItem className="my-2 my-md-0 Link">

                        {user &&   
                            <UncontrolledDropdown setActiveFromChild>
                                <DropdownToggle nav className="Link ml-0 ml-lg-4" style={{
                                    color:'#fff',
                                    fontFamily: "Roboto-Medium",
                                    fontSize: "1em",
                                    padding: "0"
                                }} caret>
                                    {user.first_name}
                                </DropdownToggle>
                                <DropdownMenu style={{    
                                    position: "absolute",
                                    top: "0px",
                                    left: "0px",
                                    transform:"translate3d(-30%, 15%, 0px)"}}
                                >
                                    <Link tabIndex="0" to="/my-trips" className="nav-item">
                                        <DropdownItem>
                                            My trips
                                        </DropdownItem>
                                    </Link>
                                    <Link tabIndex="0" to="/account-info">
                                        <DropdownItem>
                                            Account info
                                        </DropdownItem>
                                    </Link>
                                    <Link tabIndex="0" to="/payment-options">
                                        <DropdownItem>
                                            Payment options
                                        </DropdownItem>
                                    </Link>
                                    <Link tabIndex="0" onClick={logOut} to="/login">
                                        <DropdownItem>
                                            Log out
                                        </DropdownItem>
                                    </Link>
                                </DropdownMenu>
                            </UncontrolledDropdown>       
                        }      
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default NavbarContainer;
