import React, { Component } from 'react';
import {Row,Col, Button} from 'reactstrap';
import AccountAdmin from '../../layout/accountAdmin/accountAdmin';
import NewPayForm from '../newPay/newPayForm';
import CardPayment from './cardPayment';

class PaymentOptions extends Component {

    state = {
        form: false
    }

    toggle = () => {
        this.setState({ form: !this.state.form });
    };

    componentDidMount(){ this.props.getMyCards() }

    setDefaultCard = () => { this.props.setDefaultCard(event.target.value); }

    deleteCard = () => { this.props.removeCard(event.target.value); }

    render(){

        const { data, onSubmit, loader } = this.props;

        return(
            <AccountAdmin loader={loader}>
                <Row>
                    {data!= null &&
                    <Col xs={12}>
                        <Row>
                        {data.data.length > 0 && 
                            <Col xs={{ size: 11, offset: 1 }} md = {{ size: 12, offset: 0 }}>
                                <br/>
                                <h3 style={{ fontFamily: "Roboto-Medium", fontSize: "1em", color: "#D50032"}}> 
                                    Payment Methods
                                </h3>
                                {data.data.map((item) => [
                                    <CardPayment setDefaultCard = { this.setDefaultCard }
                                        key = { item.id } data = { item }
                                        deleteCard = { this.deleteCard }
                                    />
                                ])}
                                <Button onClick={ this.toggle } color="link"
                                    style={{ color: "#D50032", fontFamily: "Roboto-Medium", fontSize: "0.8em" }}>
                                    + Add another card
                                </Button>
                            </Col>
                        }
                        </Row>
                        <br/>
                        <Row>
                        {(this.state.form || data.data.length == 0) &&
                            <Col xs = {{ size: 11, offset: 1 }} md = {{ size: 8, offset: 0 }} >
                                <br/>
                                <h6 style={{ fontFamily:"Roboto-Medium", fontSize: "1em", color: "#D50032"}}> PAYMENT METHOD</h6>
                                <NewPayForm onSubmit={onSubmit} admin/>
                                <br/>
                            </Col>
                        }
                        </Row>
                    </Col>
                    }
                </Row>
            </AccountAdmin>
        )
    }
}

export default PaymentOptions;