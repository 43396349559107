import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import ProfileForm from "./ProfileForm";
import { AccountAdmin } from '../../layout';


class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {avatar: null};
    }

    setAvatar = (avatar) => {
        this.setState({avatar});
    };

    update = (data) => {
        const { update } = this.props;
        update({...data, avatar: null}, [{"file": this.state.avatar, "name": "avatar"}]);
    };

    componentDidMount(){
        this.props.getMe();
    }

    render() {

        const { me, loader, loaderDefault } = this.props;

        return (
            <AccountAdmin loader = { loader || loaderDefault } >
                <ProfileForm onSubmit={ this.update } me={ me } setAvatar={ this.setAvatar } />
            </AccountAdmin>
        );
    }
}

export default reduxForm({
    form: 'profile', // a unique identifier for this form
})(Profile);
