
import React, { Component } from 'react';
import NavbarContainer from '../layout/Navbar/Navbar';
import LoadMask from '../Utils/LoadMask/LoadMask';
import ResetPasswordForm from './ResetPasswordForm';
import { Link } from 'react-router-dom';
import NewPasswordForm from './newPasswordForm';


class ResetPassword extends Component{

    componentDidMount(){
        document.body.style.background = `url('${require("../../../../assets/img/background.png")}')`;
        if(!!this.props.match.params.token){
            this.props.setUserToken(this.props.match.params.token);
        }
    }

  render(){

    const { loader, onSubmit, submitNewPass } = this.props;

    return(
        <div>
            <NavbarContainer navToggle={this.navToggle} logOut={null} user={null} />
            <div>
                <div className="wrapper-login" style = {{ marginTop: "-97px" }}>
                    <div className="card card-login">
                        <h5 className="card-title">Recover Password</h5>
                        <div className="form">
                            <LoadMask loading={loader} dark >
                                {this.props.match.params.token 
                                  ? <NewPasswordForm onSubmit = { submitNewPass }/>
                                  : <ResetPasswordForm onSubmit = {onSubmit}/>
                                }
                                <br/>
                                <p className="link"><Link to="/login">Back</Link></p>
                                <br/>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default ResetPassword;
