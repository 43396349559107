import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SUBMIT = 'RESET_PASSWORD_SUBMIT';
const LOADER = 'RESET_PASSWORD_LOADER';
const SET_TOKEN = 'SET_TOKEN';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setToken = token => ({
    type: SET_TOKEN,
    token,
})

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user/reset_password', data).then((response) => {
        NotificationManager.success('an email has been sent to your email to reset your password', 'SUCCESS', 0);
    }).catch((e) => {
        NotificationManager.error('Error sending email, try again', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });

};

export const submitNewPass = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let store = getStore();
    data.token = store.resetPassword.token;
    api.post('user/pass_reset_password', data).then((response) => {
        NotificationManager.success('Your data has been updated, try login again', 'SUCCESS', 0);
        dispatch(push("/login"));
    }).catch((e) => {
        NotificationManager.error(`Sorry! Can\'t update your data, ${e.detail}`, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const setUserToken = (token) => (dispatch) => {
    dispatch(setToken(token));
};

export const actions = {
    onSubmit,
    submitNewPass,
    setUserToken,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_TOKEN]: (state, { token }) => {
        return {
            ...state,
            token,
        };
    },
};

export const initialState = {
    loader: false,
    token: undefined,
};

export default handleActions(reducers, initialState);
