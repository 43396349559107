import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import  PaymentOptions  from './common/components/payment/paymentOptions';
import { HomePage } from './common/components/HomePage';
import { Login, Profile, Register } from './common/components/LoginRegister';
import { ConfirmTrip, MyTrips, UpdateTrip, ConfirmEditTrip } from './common/components/trips';
import ResetPassword from './common/components/ResetPassword';
import Terms from './common/components/terms/terms';
import ProtectedRoute from './ProtectedRoute';
import NotFound from './common/components/layout/NotFound/NotFound';
import Contact from './common/components/layout/contact';
import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
require('../style/index.css');


module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/terms" component={Terms}/>
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route exact path="/reset-password/:token" component={ResetPassword} />
                <ProtectedRoute exact path="/" component={HomePage} />
                <ProtectedRoute exact path="/my-trips" component={MyTrips} />
                <ProtectedRoute exact path="/ConfirmTrip" component={ConfirmTrip}/>
                <ProtectedRoute exact path="/editTrip/:id" component={UpdateTrip} />
                <ProtectedRoute exact path="/ConfirmEditTrip/:id" component={ConfirmEditTrip}/>
                <ProtectedRoute exact path="/account-info" component={Profile}/>
                <ProtectedRoute exact path="/payment-options" component={PaymentOptions}/>
                <Route exact path="/support" component={Contact}/>
                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
