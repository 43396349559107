
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import MapWithADirectionsRenderer from '../map/DirectionRoute';
import { Modal, ModalBody, Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/payment/payment';
import { bindActionCreators } from 'redux';


class ConfirmEditTrip extends Component{

    state = {
        confirm: this.props.created,
        error: false,
        modal: false,
        responsiveMap: false,
        origin: {
            lat: undefined,
            lng: undefined,
            title:""
        },
        destination: {
            lat: undefined,
            lng: undefined,
            title:""
        },
        pickup: {},
        dropoff: {}
    }

    modalToggle = () => {
        this.setState({ modal: !this.state.modal });
    };

    confirm = () => {
        this.props.submitEditTrip(this.state.pickup,this.state.dropoff);
    }

    componentDidUpdate(){
        if(this.state.confirm != this.props.created){
            this.setState({ confirm: this.props.created })
            this.modalToggle();
        }
        if(this.state.error != this.props.error){
            this.setState({ error: this.props.error })
            this.modalToggle();
        }
    }

    setCreatedFalseTrip = () => {
        this.props.setCreatedFalseTrip();
    }

    setErrorFalseTrip = () => {
        this.props.setErrorFalseTrip();
    }

    handleSizeChange = () => {
        if(window.innerWidth < 992){
            this.setState({ responsiveMap: true })
        } else {
            this.setState({ responsiveMap: false })
        }
    }

    componentDidMount(){
        event.preventDefault();
        if(!this.props.origin.lat && !this.props.destination.lat)
            window.location.href = `/#/editTrip/${this.props.match.params.id}`
        this.setState({ origin : this.props.origin });
        this.setState({ destination: this.props.destination });
        this.setState({ pickup: this.props.pickup });
        this.setState({ dropoff: this.props.dropoff });
        this.props.actions.getMyCards();
        this.handleSizeChange();
        window.addEventListener('resize', () => { this.handleSizeChange() });
    }

    render(){

        const { onSubmit, loader, price, time, origin} = this.props;

        return(
            <div id="ConfirmEditTripPage" >
                {!this.state.responsiveMap &&
                    <div className="mapResponsive1">
                        <MapWithADirectionsRenderer 
                            fromLat = { this.state.origin.lat }
                            fromLng = { this.state.destination.lng }
                            toLat = { this.state.destination.lat }
                            toLng = { this.state.destination.lng }
                            map = {1}
                        />
                    </div>
                }
                <div className="wrapper" style= {{ zIndex: "auto", paddingTop: "95px" }}>
                    <div className="card card-confirmTrip">
                        <br/>
                        {this.state.confirm 
                            ? <h5 className="card-title"> Trip Summary </h5>
                            : <h5 className="card-title">Confirm Trip Details</h5>
                        }
                        <br/>
                        <div id="ConfirmTrip" className="form">
                            <LoadMask loading={loader} light >
                                <div className="d-flex flex-column">
                                    <div className="pl-3 d-flex flex-row">
                                        <div className="w-50 pr-1">
                                            <label> Date: </label>
                                            <span> { this.props.date ? this.props.date.format('MM/DD/YYYY') : "" } </span>
                                        </div>
                                        <div className="w-50 pl-1">
                                            <p>
                                                <label> Time:&nbsp; </label>
                                                <span>
                                                    {time
                                                        ? ( time.hour > 12
                                                            ? ( time.hour - 12 +':'+time.minutes + ' PM' )
                                                            : ( time.hour + ':' + time.minutes + ' AM' ) )
                                                        : " "
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row">
                                            <div className="pt-2 pr-2">
                                                <div className="Line-Circle"></div>
                                                <div className="Line-Line"></div>
                                            </div>
                                            <div className="flex-1">
                                                <p>
                                                    <label> Pick-Up: </label>
                                                    <span> {this.state.origin ? this.state.origin.title : ""} </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="pr-2">
                                                <div className="" style={{ height: "0.5rem" }}>
                                                    <div className="Line-Line"></div>
                                                </div>
                                                <div className="Line-Square"></div>
                                            </div>
                                            <div className="flex-1">
                                                <p>
                                                    <label> Drop-Off: </label>
                                                    <span> {this.state.destination ? this.state.destination.title : ""} </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {this.state.responsiveMap &&
                                        <div  className="mapResponsive2">
                                            <MapWithADirectionsRenderer 
                                                fromLat = { this.state.origin.lat }
                                                fromLng = { this.state.origin.lng }
                                                toLat = { this.state.destination.lat }
                                                toLng = { this.state.destination.lng }
                                                map = {2}
                                            />
                                        </div>
                                    }
                                        <br/>
                                    <div className="Amount">
                                        <p className="Sub-Amount"> <label>  Regular Charge: ${ price ? price.regular_price : "0.00" } </label> </p>
                                        <p className="Sub-Amount"> <label>  WMATA Subsidy: -${ price ? price.wmata_subsidy : "0.00" } </label> </p>
                                        <p className="Total-Amount"> <label>  You Pay: ${ price ? price.final_payment : "0.00" } </label> </p>
                                    </div>
                                    <br/>
                                    <div>
                                    {this.props.data.trip_transactions && this.props.cards &&
                                    this.props.data.trip_transactions.map((transaction) => {
                                        return(
                                            this.props.cards.data.map((item) => {
                                                let img = undefined;
                                                try{
                                                    img = require(`../../../../../assets/img/${item.cardType}.svg`);
                                                } catch (e) {
                                                    img = require('../../../../../assets/img/default.png');
                                                }
                                                return(
                                                    <Row key={item.id} className="w-100" style={{display: "flex", justifyContent:"center"}}>
                                                        <Col xs="12" align="left" >
                                                            {transaction.card == item.id && !transaction.cancelled &&
                                                                <p key={item.id} style={{ display: "flex" }} >
                                                                    <img onClick={this.toggle} width="50" src={img} />
                                                                    &nbsp;
                                                                    <span onClick={this.toggle} style={{ display:"flex", margin: "auto 0" }}> {item.cardNumber} </span>
                                                                    &nbsp;
                                                                    <i onClick={this.toggle} style={{ display:"flex", margin: "auto 0" }}  className="fa fa-chevron-down" aria-hidden="true"></i>
                                                                </p>
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                            )
                                        })
                                    }
                                        <Button color="danger" onClick={this.confirm}>
                                            CONFIRM RIDE
                                        </Button>
                                        <Modal centered={true} isOpen={this.state.modal} >
                                            <ModalBody>
                                                <Container>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <div>
                                                                <p align="center">
                                                                    {this.state.confirm 
                                                                        ?<i className="fa fa-check-circle fa-5x" aria-hidden="true"></i>
                                                                        :<i className="fa fa-times-circle fa-5x" aria-hidden="true" style={{ color: "#D50032" }} ></i>                                                                
                                                                    }
                                                                </p>
                                                                <br/> <br/>
                                                                <h1 style={{ fontFamily: "Montserrat-SemiBold", fontSize: "3.5em", color: "#252F38" }} align="center"> 
                                                                    { this.state.confirm ? "SUCCESS" : "OOPS" }
                                                                </h1>
                                                                <br/>
                                                                <p align="center">
                                                                    {this.state.confirm 
                                                                        ? "You ride has been edited. We look forward to seeing you."
                                                                        : "Something went wrong. Please check your method of payment."
                                                                    }
                                                                </p>
                                                                <br/> <br/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={{ size: 8, offset: 2}}>
                                                            {this.state.confirm 
                                                              ? <Link to="/my-trips" style={{ textDecoration: "none" }}> <Button block onClick={this.setCreatedFalseTrip} color="danger"> CONTINUE </Button> </Link>
                                                              : <Button block color="danger" onClick={this.setErrorFalseTrip} > GO BACK </Button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                    <br/>
                                </div>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Modal.propTypes = {
    isOpen:  PropTypes.bool,
    centered: PropTypes.bool,
    toggle:  PropTypes.func,
    fade: PropTypes.bool,
}


function mapStateToProps (state){
    return {
        cards: state.payment.data
    }
}

function mapDispatchToProps (dispatch){
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEditTrip);

