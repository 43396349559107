import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../Utils/renderField';


const ResetPasswordForm = (props) => {

    const { handleSubmit } = props;

    return (
        <form name="resetPasswordForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <label htmlFor="username">WMATA ID Number</label>
                <Field 
                    name="username"
                    label="WMATA ID Number"
                    placeholder="WMATA ID Number"
                    component={renderField}
                    type="text"
                    className="form-control" />
            </div>
            <br/>
            <div className="buttons-box">
                <button type="submit" className="btn btn-danger align-self-center">
                    CONFIRM
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'resetPasswordForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('This field is required'),
        });
    },
})(ResetPasswordForm);
