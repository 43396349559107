
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import UpdateTripForm from './UpdateTripForm';


class UpdateTrip extends Component{

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(){
        event.preventDefault();
        document.body.style.background = `url('${require(`../../../../../assets/img/background-homepage.png`)}')`;
        this.props.editTrip(this.props.match.params.id);
    }

    render(){

        const { onSubmitEdit, loader, data } = this.props;
        const tam = Object.keys(data).length > 0 && !loader ? "100%" : "100vh";

        return(
            <div id="HomePage">
                <div className="wrapper">
                    <div className="card card-newTrip w-100" style={{ height: tam }}>
                        <h5 className="card-title">Trip Edition</h5>
                        <div className="form" id="form-updateTrip">
                            <LoadMask loading={loader} light >
                            {Object.keys(data).length > 0 && !loader &&
                                <UpdateTripForm 
                                onSubmit={onSubmitEdit}
                                data = { data }
                                id={this.props.match.params.id}
                                />
                            }
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default UpdateTrip;
