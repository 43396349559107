import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Container, Row, Col } from 'reactstrap';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import NewPayForm from './newPayForm';

class NewPay extends Component{

  state = {
    modal: false,
    nestedModal: false,
    closeAll: false
  }

  toggle = () => {
      if(!this.state.modal) {
        this.props.getMyCards();
      }
      this.setState({ modal: !this.state.modal });
    };

  toggleNested = () => {
    this.setState({nestedModal: !this.state.nestedModal});
    this.setState({closeAll: false});
  }

  toggleAll = () => {
    this.setState({nestedModal: !this.state.nestedModal});
    this.setState({closeAll: true});
  }

  setPaymentCard = (event) => {
    this.props.setPaymentCard(parseInt(event.target.id));
    this.toggle();
  }

  componentDidMount(){
    event.preventDefault();
    this.props.getMyCards();
  }

  render(){

  const { className, loader, data, onSubmit, confirm } = this.props;

    return(
      <div >
    {data != undefined && data.data.length > 0 ? (
      <div>
        {data.data.map((item) => {
            let img = undefined;
            try{
              img = require(`../../../../../assets/img/${item.cardType}.svg`);
            } catch (e) {
              img = require('../../../../../assets/img/default.png');
            }
          if(this.props.id_card){
            if(this.props.id_card == item.id){
              return(
                <p key={item.id} style={{ display: "flex", cursor: "pointer"}} >
                  <img onClick={this.toggle} width="50" src={img} />
                  &nbsp;
                  <span onClick={this.toggle} style={{ display:"flex", margin: "auto 0" }}> {item.cardNumber} </span>
                  &nbsp;
                  <i onClick={this.toggle} style={{ display:"flex", margin: "auto 0" }}  className="fa fa-chevron-down" aria-hidden="true"></i>
                </p>
              )
            }
          } else {
            if(item.default){
              this.props.setPaymentCard(item.id);
              return(
                <p key={item.id} style={{ display: "flex"}} >
                  <img onClick={this.toggle} width="50" src={img} />
                  &nbsp;
                  <span onClick={this.toggle} style={{ display:"flex", margin: "auto 0" }}> {item.cardNumber} </span>
                  &nbsp;
                  <i onClick={this.toggle} style={{ display:"flex", margin: "auto 0" }}  className="fa fa-chevron-down" aria-hidden="true"></i>
                </p>
              )
            }
          }

        })}
        <Button color="danger" onClick={confirm}>
          CONFIRM RIDE
        </Button>
        <Modal fade={true} centered={true} isOpen={this.state.modal} toggle={this.toggle} className={className}>
        <ModalHeader toggle={this.toggle}> Chose payment method </ModalHeader>
        <ModalBody>
            <LoadMask loading={loader} light >
            {data &&
                <Container>
                    {data.data.map((item) => {
                      let img = undefined;
                      try{
                        img = require(`../../../../../assets/img/${item.cardType}.svg`);
                      } catch (e) {
                        img = require('../../../../../assets/img/default.png');
                      }
                        return(
                            <Row key={item.id} className="w-100" style={{display: "flex", justifyContent:"center"}}>
                                <Col xs="12" align="left" >
                                  <Button  id={item.id}
                                    style={{ display:"flex",
                                    justifyContent:"center",
                                    alignItems: "center",
                                    fontFamily: "Montserrat-Medium",
                                    fontSize: "1em"
                                  }}
                                    onClosed={this.closeAll ? this.toggle : undefined}
                                    value={item.id} onClick={this.setPaymentCard}
                                    outline color="light" className="m-2 w-100"
                                    >

                                    <img id={item.id} style={{width: "20%"}} src={img}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {"xxxx"+item.cardNumber} <br/> {/* 08/2024 */}

                                  </Button>
                                </Col>
                            </Row>
                        )
                    })
                    }
                </Container>
            }
            </LoadMask>
          <br />
          <Button color="light" block onClick={this.toggleNested}> Add payment method </Button>
          <Modal centered={true} isOpen={this.state.nestedModal} toggle={this.toggleNested} onClosed={this.closeAll ? this.toggle : undefined}>
            <ModalHeader toggle={this.toggleNested}> Add credit or debit card </ModalHeader>
            <ModalBody>
                <NewPayForm onSubmit={onSubmit} toggleNested={this.toggleNested}/>
            </ModalBody>
          </Modal>
        </ModalBody>
      </Modal>
      </div>
    ) : (
      <div>
      <Button color="danger" onClick={this.toggle}> ADD PAYMENT METHOD </Button>
      <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle} onClosed={this.closeAll ? this.toggle : undefined}>
        <ModalHeader toggle={this.toggle}> Add credit or debit card </ModalHeader>
        <ModalBody>
          <NewPayForm onSubmit={onSubmit} toggleNested={this.toggle}/>
        </ModalBody>
      </Modal>
    </div>
      )
    }
    </div>
    )
  }
}

Modal.propTypes = {
  isOpen:  PropTypes.bool,
  centered: PropTypes.bool,
  toggle:  PropTypes.func,
  fade: PropTypes.bool,
  onClosed: PropTypes.func,
}

export default NewPay;
