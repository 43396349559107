import React, { Component } from 'react';
import SideBar from '../SideBar/SideBar';
import { Col, Row } from 'reactstrap';
import { logOut } from '../../../../redux/modules/cuenta/login';
import { connect } from 'react-redux';
import LoadMask from '../../Utils/LoadMask/LoadMask';


class AccountAdmin extends Component {

    componentDidMount(){
        document.body.style.background = "";
    }

    render(){

        const { logOut, loader } = this.props;
        
        return(
            <Row >
                <Col xs={12} style={{ position: "absolute" }}>
                        <br/>
                        <Row style={{ paddingTop: "1em" }}>
                            <Col xs={12} md={4} lg={{ size: 3, offset: 1 }}>
                                <SideBar logOut = { logOut } />
                            </Col>
                            <Col xs={12} sm={{size: 10, offset: 1}} md={{ size: 7, offset: 0 }} lg={{ size: 6, offset: 0 }}>
                                <LoadMask loading = { loader } >
                                    { this.props.children }
                                </LoadMask>
                            </Col>
                        </Row>
                </Col>
            </Row>
        )
    }
}

export default connect(null,{logOut})(AccountAdmin);
