import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderFieldCheck } from '../../Utils/renderField/renderField';
import autoCompleteDirections from '../map';
import renderDatePicker from '../../Utils/renderField/renderDatePicker';
import renderTimePicker from '../../Utils/TimePicker';
import "../../Utils/TimePicker/css/time-picker.css";


const NewTripForm = (props) => {

    const { handleSubmit } = props;

    return (
        <form name="newTripForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <br/>
            <div className="form-group has-feedback">
                <label htmlFor="date" className="db-font-size pb-2"> Date </label>
                <Field
                    name="date"
                    placeholder="Date"
                    className="flex-1 w-100 m-0 p-0"
                    component={renderDatePicker}
                    numberOfMonths={1}
                />
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="time" className="db-font-size pb-2"> Time </label>
                <Field id="timepicker" name="time" component={renderTimePicker} />
            </div>

            <div className="form-group has-feedback">
                <label htmlFor="pickup" className="db-font-size pb-2"> Pick-Up Location </label>
                <Field name="pickup" label="Pick-Up Location" component={autoCompleteDirections}
                type="text" className="form-control" placeholder="First St SE, Washington, DC 20004"/>
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="dropoff" className="db-font-size pb-2"> Drop-off Location </label>
                <Field name="dropoff" label="Drop-off Location" component={autoCompleteDirections}
                type="text" className="form-control" placeholder="2700 F St NW, Washington, DC 20566" />
            </div>
            <div className="form-group has-feedback">
                <Field
                    name="wheelchair"
                    component={renderFieldCheck}
                    label={<label>&nbsp;Wheel chair accessible</label>}
                />
            </div>
            <br/>
            <div className="buttons-box">
                <button type="submit" className="btn btn-danger align-self-center">
                    BOOK RIDE
                </button>
            </div>
            <br/>
        </form>
    );
};

export default reduxForm({
    form: 'newTripForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            date: validators.exists()('This field is Required'),
            time: validators.exists()('This field is Required'),
            pickup: validators.exists()('This field is Required'),
            dropoff: validators.exists()('This field is Required'),
            datePicker: validators.exists()('This field is Required'),
        });
    },
})(NewTripForm);
