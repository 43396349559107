
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Collapse, Button, Row, Col, Modal, ModalBody, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { actions, getMyDeleteCards } from '../../../../redux/modules/payment/payment';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

class CardTrip extends Component{

    state = {
        collapse: false,
        status: <svg className="bi bi-plus" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z" clipRule="evenodd"/>
        <path fillRule="evenodd" d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z" clipRule="evenodd"/>
      </svg>,
        style: "",
        modal: false,
      }

    modalToggle = () => {
        this.setState({ modal: !this.state.modal });
    };

    setCollapse(value){
        if(value){
            this.setState({style: ""});
        } else {
            this.setState({style: "0.5px solid #707070"});
        }
        this.setState({ collapse: !value });
    }

    setStatus(value){ this.setState({ status: value }); }

    onEntering = () => { this.setStatus(<svg className="bi bi-three-dots" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M3 9.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" clipRule="evenodd"/>
  </svg>) };

    onEntered = () => { this.setStatus(<svg className="bi bi-dash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M3.5 8a.5.5 0 01.5-.5h8a.5.5 0 010 1H4a.5.5 0 01-.5-.5z" clipRule="evenodd"/>
  </svg>) };

    onExiting = () => { this.setStatus(<svg className="bi bi-three-dots" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M3 9.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" clipRule="evenodd"/>
  </svg>) };

    onExited = () => { this.setStatus(<svg className="bi bi-plus" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z" clipRule="evenodd"/>
    <path fillRule="evenodd" d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z" clipRule="evenodd"/>
  </svg>) };

    toggle = () => { this.setCollapse(this.state.collapse) };

    componentDidMount(){
        this.props.actions.getMyCards();
        this.props.actions.getMyDeleteCards();
    }

    cancelTrip = () => {
        event.preventDefault();
        this.props.cancelTrip(event.target.value);
        this.modalToggle();
    }

    render(){

        const { data, cards, deleteCard } = this.props;
        let existCard = false;

        return(
            <Row key = {data.id} style={{ border:` ${this.state.style}`, paddingTop: "0.4em" }} className="cardTrip my-1">
                <Col xs={1} align="right" className="p-0" style={{ top: "-0.25em", margin:"10px"}}>
                    <Button color="light" onClick={ this.toggle }
                        style={{ fontSize: "1.4em", padding: "0px", margin: "0px", color:"#707070" }}
                    >
                        { this.state.status }
                    </Button>
                </Col>
                <Col xs={5} sm={6} align="left">
                    <small style={{fontFamily: "Montserrat-Regular"}}>
                        { moment(data.trip_pick_up_date).format('LL hh:mm A') }
                    </small>
                    <div style={{ fontFamily: "Montserrat-Semibold", fontSize: "1.1em"}}>
                        {data.trip_transactions.map((transaction) => {
                            return (
                                <span key = { transaction.id } >
                                {transaction.cancelled
                                  ? <p style={{ textDecoration: "line-through" }} className="mb-0">
                                        USD ${ transaction.amount }
                                    </p>
                                  : <p className="mb-0">
                                        USD ${ transaction.amount }
                                    </p>
                                }
                                </span>
                            )
                        })
                        }
                    </div>
                </Col>
                <Col xs={5} sm={4} align="right">
                    {data.trip_cancelled 
                      ? <Button color="warning"> Canceled </Button>
                      : ( data.trip_finished 
                          ? <Button color="success" style={{ color: "rgba(0,0,0,0.7)" }}> Completed </Button>
                          : (moment().format('L') == moment(data.trip_pick_up_date).format('L')
                              ? <Button color="info" style={{ color: "#000" }}> Today </Button>
                              : <Button color="danger" > Upcoming </Button>
                            )
                        )
                    }
                    <p className="mb-0"> {data.trip_pick_up_city} </p>
                    {cards && 
                        <div>
                            {data.trip_transactions.map((transaction) => {
                                let findCard = false;
                                return(
                                    <div key={transaction.id}>
                                        {cards.data.map((card) => {
                                            let img = undefined;
                                            try{
                                                img = require(`../../../../../assets/img/${card.cardType}.svg`)
                                            } catch (e) {
                                                img = require(`../../../../../assets/img/default.png`)
                                            }
                                            return (
                                                <div key={card.id}>
                                                    {transaction.card==card.id &&
                                                    <p className="mb-0">
                                                        {findCard = true,
                                                        existCard = true}
                                                        <img src={img} width="40"/> 
                                                        •••• {card.cardNumber}
                                                    </p>
                                                    }
                                                </div>
                                            )
                                            })
                                        }
                                        {!findCard && deleteCard &&
                                            deleteCard.deleteCard.map((card)=>{
                                                let img = undefined;
                                                try{
                                                    img = require(`../../../../../assets/img/${card.cardType}.svg`)
                                                } catch (e) {
                                                    img = require(`../../../../../assets/img/default.png`)
                                                }
                                                return (
                                                    <div key={card.id}>
                                                        {transaction.card==card.id &&
                                                        <p className="mb-0">
                                                            {findCard = true, existCard = false}
                                                            <img src={img} width="40"/>
                                                            •••• {card.cardNumber}
                                                        </p>
                                                        }
                                                    </div>
                                                )
                                                })
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    }
                </Col>
                <Col xs={11}>
                    <Collapse
                        isOpen = { this.state.collapse }
                        onEntering = { this.onEntering }
                        onEntered = { this.onEntered }
                        onExiting = { this.onExiting }
                        onExited = { this.onExited }
                        style = {{ paddingRight: "0%" }}
                    >
                        <Row >
                            <Col xs={{ size: 1 }} md={{ size: 1, offset: 1}} align="center">
                                <div className="Line" style={{ top: "2%", right: "0"}}> 
                                    <div className="Line-Circle" style={{ backgroundColor: "#00000026" }}></div>
                                    <div className="Line-Line" style={{ height: "2.8em", border: "1px solid #00000026", left: "0"}}></div>
                                    <div className="Line-Square" style={{ backgroundColor: "#00000026" }}></div>
                                </div>
                            </Col>
                            <Col xs={10} align="left">
                                <p style={{ fontSize: "0.7em"}}>
                                   { data.trip_pick_up_location }
                                    <br/>
                                    { moment(data.trip_pick_up_date).format('hh:mm A') }
                                    <br/> <br/>
                                    { data.trip_drop_off_location }
                                    <br/>
                                </p>
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={12} align="right">
                                {!data.trip_finished && !data.trip_cancelled && existCard
                                && moment(data.trip_pick_up_date).diff(moment().format(), 'hours') > 1 &&
                                    <Link to={`editTrip/${data.id}`}> 
                                        <Button color="light"
                                            style={{ marginBottom: '1rem', color: "#DA291C", width: "30%"}}>
                                            Edit Trip 
                                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                        </Button>
                                    </Link>
                                }
                                {!data.trip_finished && !data.trip_cancelled 
                                && moment(data.trip_pick_up_date).diff(moment().format(), 'hours') >= 0 &&
                                    <span>
                                        <Button color="light" onClick={this.modalToggle} value = { data.id } color="light"
                                            style={{ width: "35%", marginBottom: '1rem', color: "#DA291C" }}
                                        >
                                            Cancel Trip &nbsp;
                                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                        </Button>
                                        <Modal centered={true} isOpen={this.state.modal} toggle={this.modalToggle}>
                                        <ModalBody>
                                            <Container>
                                                <Row>
                                                    <Col xs={12}>
                                                        <h1 style={{ fontFamily: "Montserrat-SemiBold", fontSize: "2em", color: "#252F38" }}> 
                                                            Are you sure you want to cancel this trip?
                                                        </h1>
                                                        {moment(this.props.data.trip_pick_up_date).diff(moment().format(), 'hours') < 2 && 
                                                            <p> Payment cannot be refunded before two hours of travel </p>
                                                        }
                                                        <br/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={6}>
                                                        <Button block outline color="danger" onClick={this.modalToggle}> 
                                                            NO 
                                                        </Button>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Button block color="danger" value = { data.id } onClick={this.cancelTrip}> 
                                                            YES 
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </ModalBody>
                                        </Modal>
                                    </span>
                                }
                            </Col>
                        </Row>
                    </Collapse>
                </Col>
            </Row>
        )
    }
}


Modal.propTypes = {
    isOpen:  PropTypes.bool,
    centered: PropTypes.bool,
    toggle:  PropTypes.func,
    fade: PropTypes.bool,
    onClosed: PropTypes.func,
    className: PropTypes.string,
}

function mapStateToProps (state ){
    return {
        cards: state.payment.data,
        deleteCard: state.payment.deleteCard
    }
}

function mapDispatchToProps (dispatch){
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CardTrip);
