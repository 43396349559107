import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/trips/trips';
import ConfirmTrip from './ConfirmTrip';


const ms2p = (state) => {
  return {
    ...state.trips,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ConfirmTrip);